import * as ctes from "./constants";

export const checkPagesAvailable = () => ({
  type: ctes.CHECK_PAGES_AVAILABLE,
});

export const checkPagesSuccess = (numPages) => ({
  type: ctes.CHECK_PAGES_SUCCESS,
  payload: numPages,
});

export const checkPagesFailure = (error) => ({
  type: ctes.CHECK_PAGES_FAILURE,
  payload: error,
});

export const clearExamModule = () => ({
  type: ctes.CLEAR_EXAM_MODULE,
});

export const examDownloadSuccess = (id) => ({
  type: ctes.EXAM_DOWNLOAD_SUCCESS,
  payload: id,
});

export const uploadRecognition = (file) => ({
  type: ctes.UPLOAD_RECOGNITION,
  payload: file,
});

export const uploadRecognitionSuccess = (id) => ({
  type: ctes.UPLOAD_RECOGNITION_SUCCESS,
  payload: id,
});

export const uploadRecognitionFailure = (error) => ({
  type: ctes.UPLOAD_RECOGNITION_FAILURE,
  payload: error,
});

export const uploadExam = (files, authorization = false, scholarship = undefined) => ({
  type: ctes.UPLOAD_EXAM,
  payload: { files, authorization, scholarship },
});

export const uploadExamSuccess = (id) => ({
  type: ctes.UPLOAD_EXAM_SUCCESS,
  payload: id,
});

export const uploadExamFailure = (error) => ({
  type: ctes.UPLOAD_EXAM_FAILURE,
  payload: error,
});

export const uploadExamMobile = (file) => ({
  type: ctes.UPLOAD_EXAM_MOBILE,
  payload: file,
});

export const uploadExamMobileSuccess = (id) => ({
  type: ctes.UPLOAD_EXAM_MOBILE_SUCCESS,
  payload: id,
});

export const uploadExamMobileFailure = (error) => ({
  type: ctes.UPLOAD_EXAM_MOBILE_FAILURE,
  payload: error,
});

export const fetchClassifications = (id, scholarship = undefined) => ({
  type: ctes.FETCH_CLASSIFICATIONS,
  payload: { id, scholarship },
});

export const fetchRecognized = (recognized) => ({
  type: ctes.FETCH_RECOGNIZED,
  payload: recognized,
});

export const changePageSuccess = () => ({
  type: ctes.CHANGE_PAGE_SUCCESS,
});

export const changePageFailure = () => ({
  type: ctes.CHANGE_PAGE_FAILURE,
});

export const fetchChangePage = (action) => ({
  type: ctes.FETCH_CHANGE_PAGE,
  payload: action,
});

export const sendImages = (images) => ({
  type: ctes.SEND_IMAGES,
  payload: images,
});

export const sendImagesSuccess = (urls) => ({
  type: ctes.SEND_IMAGES_SUCCESS,
  payload: urls,
});

export const sendImagesFailure = (error) => ({
  type: ctes.SEND_IMAGES_FAILURE,
  payload: error,
});

export const classifyExam = (classification) => ({
  type: ctes.CLASSIFY_EXAM,
  payload: classification,
});

export const classifyExamSuccess = (id) => ({
  type: ctes.CLASSIFY_EXAM_SUCCESS,
  payload: id,
});

export const classifyExamFailure = (error) => ({
  type: ctes.CLASSIFY_EXAM_FAILURE,
  payload: error,
});

export const fetchOnlineExam = (
  examId,
  studentId = undefined,
  isOpen = false
) => ({
  type: ctes.FETCH_ONLINE_EXAM,
  payload: { examId, studentId, isOpen },
});

export const fetchOnlineExamSuccess = (resolutions) => ({
  type: ctes.FETCH_ONLINE_EXAM_SUCCESS,
  payload: resolutions,
});

export const fetchOnlineExamFailure = (error) => ({
  type: ctes.FETCH_ONLINE_EXAM_FAILURE,
  payload: error,
});

export const fetchOnlineExamInfo = (examId) => ({
  type: ctes.FETCH_ONLINE_EXAM_INFO,
  payload: { examId },
});

export const fetchOnlineExamInfoSuccess = (exam) => ({
  type: ctes.FETCH_ONLINE_EXAM_INFO_SUCCESS,
  payload: exam,
});

export const fetchOnlineExamInfoFailure = (error) => ({
  type: ctes.FETCH_ONLINE_EXAM_INFO_FAILURE,
  payload: error,
});

export const fetchPowerBIReport = (examIds, newReport) => ({
  type: ctes.FETCH_POWERBI_REPORT,
  payload: { examIds, newReport },
});

export const fetchExamReport = (examId, newReport) => ({
  type: ctes.FETCH_EXAM_REPORT,
  payload: { examId, newReport },
});

export const fetchPowerBIReportSuccess = (reportInfo) => ({
  type: ctes.FETCH_POWERBI_REPORT_SUCCESS,
  payload: reportInfo,
});

export const fetchPowerBIReportFailure = (error) => ({
  type: ctes.FETCH_POWERBI_REPORT_FAILURE,
  payload: error,
});

export const fetchResultExam = (
  title,
  page,
  dateFrom,
  dateTotags,
  itemsPerPage
) => ({
  type: ctes.FETCH_RESULT_EXAM,
  payload: { title, page, dateFrom, dateTotags, itemsPerPage },
});

export const fetchResultExamSuccess = (resolutions) => ({
  type: ctes.FETCH_RESULT_EXAM_SUCCESS,
  payload: resolutions,
});

export const fetchResultExamFailure = (error) => ({
  type: ctes.FETCH_RESULT_EXAM_FAILURE,
  payload: error,
});

export const fetchIndividualResultExam = (
  title,
  page,
  dateFrom,
  dateTotags,
  itemsPerPage
) => ({
  type: ctes.FETCH_INDIVIDUAL_RESULT_EXAM,
  payload: { title, page, dateFrom, dateTotags, itemsPerPage },
});

export const fetchIndividualResultExamSuccess = (resolutions) => ({
  type: ctes.FETCH_INDIVIDUAL_RESULT_EXAM_SUCCESS,
  payload: resolutions,
});

export const fetchIndividualResultExamFailure = (error) => ({
  type: ctes.FETCH_INDIVIDUAL_RESULT_EXAM_FAILURE,
  payload: error,
});

export const fetchStudentExams = (
  title,
  page,
  dateFrom,
  dateTotags,
  itemsPerPage
) => ({
  type: ctes.FETCH_STUDENT_EXAMS,
  payload: { title, page, dateFrom, dateTotags, itemsPerPage },
});

export const fetchStudentExamsSuccess = (resolutions) => ({
  type: ctes.FETCH_STUDENT_EXAMS_SUCCESS,
  payload: resolutions,
});

export const fetchStudentExamsFailure = (error) => ({
  type: ctes.FETCH_STUDENT_EXAMS_FAILURE,
  payload: error,
});

export const fetchStudentResultExam = ({
  examId,
  page,
  itemsPerPage,
  districts = null,
  newReport = false
}) => ({
  type: ctes.FETCH_STUDENT_RESULT_EXAM,
  payload: { examId, page, itemsPerPage, districts, newReport },
});

export const fetchStudentResultExamSuccess = (resolutions) => ({
  type: ctes.FETCH_STUDENT_RESULT_EXAM_SUCCESS,
  payload: resolutions,
});

export const fetchStudentResultExamFailure = (error) => ({
  type: ctes.FETCH_STUDENT_RESULT_EXAM_FAILURE,
  payload: error,
});

export const fetchStudentIndividualResultExam = (
  examId,
  studentId = undefined
) => ({
  type: ctes.FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM,
  payload: { examId, studentId },
});

export const fetchStudentIndividualResultExamSuccess = (resolutions) => ({
  type: ctes.FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM_SUCCESS,
  payload: resolutions,
});

export const fetchStudentIndividualResultExamFailure = (error) => ({
  type: ctes.FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM_FAILURE,
  payload: error,
});

export const fetchStudentResult = (examId, userId, isOpen = false) => ({
  type: ctes.FETCH_STUDENT_RESULT,
  payload: { examId, userId, isOpen },
});

export const fetchStudentResultSuccess = (resolutions) => ({
  type: ctes.FETCH_STUDENT_RESULT_SUCCESS,
  payload: resolutions,
});

export const fetchStudentResultFailure = (error) => ({
  type: ctes.FETCH_STUDENT_RESULT_FAILURE,
  payload: error,
});

export const fetchStudentResultAll = (userId) => ({
  type: ctes.FETCH_STUDENT_RESULT_ALL,
  payload: { userId },
});

export const fetchStudentResultAllSuccess = (result) => ({
  type: ctes.FETCH_STUDENT_RESULT_ALL_SUCCESS,
  payload: result,
});

export const fetchStudentResultAllFailure = (error) => ({
  type: ctes.FETCH_STUDENT_RESULT_ALL_FAILURE,
  payload: error,
});

export const startOnlineExam = (
  examId,
  studentId = undefined,
  isOpen = false
) => ({
  type: ctes.START_ONLINE_EXAM,
  payload: { examId, studentId, isOpen },
});

export const startOnlineExamSuccess = (userId) => ({
  type: ctes.START_ONLINE_EXAM_SUCCESS,
  payload: { userId },
});

export const startOnlineExamFailure = (error) => ({
  type: ctes.START_ONLINE_EXAM_FAILURE,
  payload: error,
});

export const endOnlineExam = (
  examId,
  studentId = undefined,
  isOpen = false
) => ({
  type: ctes.END_ONLINE_EXAM,
  payload: { examId, studentId, isOpen },
});

export const endOnlineExamSuccess = () => ({
  type: ctes.END_ONLINE_EXAM_SUCCESS,
});

export const endOnlineExamFailure = (error) => ({
  type: ctes.END_ONLINE_EXAM_FAILURE,
  payload: error,
});

export const ansQuestion = (ans, isOpen = false) => ({
  type: ctes.ANS_QUESTION,
  payload: { ans, isOpen },
});

export const ansQuestionSuccess = () => ({
  type: ctes.ANS_QUESTION_SUCCESS,
});

export const ansQuestionFailure = (error) => ({
  type: ctes.ANS_QUESTION_FAILURE,
  payload: error,
});

export const fetchExam = (id) => ({
  type: ctes.FETCH_EXAM,
  payload: id,
});

export const fetchExamSuccess = (resolutions) => ({
  type: ctes.FETCH_EXAM_SUCCESS,
  payload: resolutions,
});

export const fetchExamFailure = (error) => ({
  type: ctes.FETCH_EXAM_FAILURE,
  payload: error,
});

export const fetchExamResult = (id) => ({
  type: ctes.FETCH_EXAM_RESULT,
  payload: id,
});

export const fetchExamResultSuccess = (resolutions) => ({
  type: ctes.FETCH_EXAM_RESULT_SUCCESS,
  payload: resolutions,
});

export const fetchExamResultFailure = (error) => ({
  type: ctes.FETCH_EXAM_RESULT_FAILURE,
  payload: error,
});

export const fetchExamImage = (id) => ({
  type: ctes.FETCH_EXAM_IMAGE,
  payload: id,
});

export const fetchExamImageSuccess = (resolutions) => ({
  type: ctes.FETCH_EXAM_IMAGE_SUCCESS,
  payload: resolutions,
});

export const fetchExamImageFailure = (error) => ({
  type: ctes.FETCH_EXAM_IMAGE_FAILURE,
  payload: error,
});

export const fetchExamMatrixEF = (companyId) => ({
  type: ctes.FETCH_EXAM_MATRIX_EF,
  payload: { companyId },
});

export const fetchExamMatrixEFSuccess = (data) => ({
  type: ctes.FETCH_EXAM_MATRIX_EF_SUCCESS,
  payload: data,
});

export const fetchExamMatrixEFFailure = (error) => ({
  type: ctes.FETCH_EXAM_MATRIX_EF_FAILURE,
  payload: error,
});

export const fetchExamMatrixEM = (companyId) => ({
  type: ctes.FETCH_EXAM_MATRIX_EM,
  payload: { companyId },
});

export const fetchExamMatrixEMSuccess = (data) => ({
  type: ctes.FETCH_EXAM_MATRIX_EM_SUCCESS,
  payload: data,
});

export const fetchExamMatrixEMFailure = (error) => ({
  type: ctes.FETCH_EXAM_MATRIX_EM_FAILURE,
  payload: error,
});

export const fetchExamHabilities = (companyId) => ({
  type: ctes.FETCH_EXAM_HABILITIES,
  payload: { companyId },
});

export const fetchExamHabilitiesSuccess = (data) => ({
  type: ctes.FETCH_EXAM_HABILITIES_SUCCESS,
  payload: data,
});

export const fetchExamHabilitiesFailure = (error) => ({
  type: ctes.FETCH_EXAM_HABILITIES_FAILURE,
  payload: error,
});

export const fetchEquationImage = (id) => ({
  type: ctes.FETCH_EQUATION_IMAGE,
  payload: id,
});

export const fetchEquationImageSuccess = (resolutions) => ({
  type: ctes.FETCH_EQUATION_IMAGE_SUCCESS,
  payload: resolutions,
});

export const fetchEquationImageFailure = (error) => ({
  type: ctes.FETCH_EQUATION_IMAGE_FAILURE,
  payload: error,
});

export const downloadExam = (data) => ({
  type: ctes.DOWNLOAD_EXAM,
  payload: data,
});

export const downloadExamSuccess = (resolutions) => ({
  type: ctes.DOWNLOAD_EXAM_SUCCESS,
  payload: resolutions,
});

export const downloadExamFailure = (error) => ({
  type: ctes.DOWNLOAD_EXAM_FAILURE,
  payload: error,
});

export const finishExam = (resolutions) => ({
  type: ctes.FINISH_EXAM,
  payload: resolutions,
});

export const finishExamSuccess = () => ({
  type: ctes.FINISH_EXAM_SUCCESS,
});

export const finishExamFailure = (error) => ({
  type: ctes.FINISH_EXAM_FAILURE,
  payload: error,
});

export const fetchExams = (
  title,
  page,
  tags,
  itemsPerPage,
  userId,
  companyId,
  isSimulie,
  examType = "Todas",
  queryTree = "",
  examStatus = "",
) => ({
  type: ctes.FETCH_EXAMS,
  payload: { title, page, tags, itemsPerPage, userId, companyId, isSimulie, examType, queryTree, examStatus },
});

export const fetchExamsSuccess = (exams) => ({
  type: ctes.FETCH_EXAMS_SUCCESS,
  payload: exams,
});

export const fetchExamsFailure = (error) => ({
  type: ctes.FETCH_EXAMS_FAILURE,
  payload: error,
});

export const createQuestionsByAI = (payload) => ({
  type: ctes.CREATE_QUESTIONS_BY_AI,
  payload: payload,
  loading: true,
});

export const createQuestionsByAISuccess = (questions, loading = false) => ({
  type: ctes.CREATE_QUESTIONS_BY_AI_SUCCESS,
  loading: loading,
  payload: questions,
});

export const createQuestionsByAIFailure = (error) => ({
  type: ctes.CREATE_QUESTIONS_BY_AI - FAILURE,
  loading: false,
  payload: error,
});

export const deleteExam = (id) => ({
  type: ctes.DELETE_EXAM,
  payload: id,
});

export const deleteExamSuccess = () => ({
  type: ctes.DELETE_EXAM_SUCCESS,
});

export const deleteExamFailure = (error) => ({
  type: ctes.DELETE_EXAM_FAILURE,
  payload: error,
});

export const deleteTagExam = (
  examId,
  tag,
  title,
  page,
  tags,
  itemsPerPage,
  userId,
  companyId
) => ({
  type: ctes.DELETE_TAG,
  payload: { examId, tag, title, page, tags, itemsPerPage, userId, companyId },
});

export const deleteTagExamSuccess = (examsList) => ({
  type: ctes.DELETE_TAG_SUCCESS,
  payload: examsList,
  loading: false,
});

export const deleteTagExamFailure = (error) => ({
  type: ctes.DELETE_TAG_FAILURE,
  payload: error,
  loading: false,
});

export const addTagExam = (examId, tag) => ({
  type: ctes.ADD_TAG,
  payload: { examId, tag },
});

export const addTagSuccess = () => ({
  type: ctes.ADD_TAG_SUCCESS,
  loading: false,
});

export const addTagFailure = (error) => ({
  type: ctes.ADD_TAG_FAILURE,
  payload: error,
  loading: false,
});

export const setExamId = (examId, editable) => ({
  type: ctes.SET_EXAM_ID,
  payload: { examId, editable },
});

export const setCanEdit = (canEdit) => ({
  type: ctes.SET_CAN_EDIT,
  payload: { canEdit },
});

export const fetchSelected = (examsId, selected) => ({
  type: ctes.FETCH_SELECTED,
  payload: { examsId, selected },
});

export const fetchSelectedSuccess = (exams) => ({
  type: ctes.FETCH_SELECTED_SUCCESS,
  payload: exams,
});

export const fetchSelectedFailure = (error) => ({
  type: ctes.FETCH_SELECTED_FAILURE,
  payload: error,
});

export const deleteQuestion = (examId, questionId) => ({
  type: ctes.DELETE_QUESTION,
  payload: { examId, questionId },
});

export const deleteQuestionSuccess = (selected) => ({
  type: ctes.DELETE_QUESTION_SUCCESS,
  payload: selected,
});

export const deleteQuestionFailure = (error) => ({
  type: ctes.DELETE_QUESTION_FAILURE,
  payload: error,
});

export const searchExamTag = (tags) => ({
  type: ctes.SEARCH_EXAM_TAG,
  payload: { tags },
});

export const searchExamTagSuccess = (exams) => ({
  type: ctes.SEARCH_EXAM_TAG_SUCCESS,
  payload: exams,
});

export const searchExamTagFailure = (error) => ({
  type: ctes.SEARCH_EXAM_TAG_FAILURE,
  payload: error,
});

export const selectedExams = (exams) => ({
  type: ctes.SELECTED_EXAMS,
  payload: exams,
});

export const setQuestions = (questions) => ({
  type: ctes.SELECTED_QUESTIONS,
  payload: questions,
});

export const setCartQuestions = (questions) => ({
  type: ctes.SET_CART_QUESTIONS,
  payload: questions,
});

export const selecionarQuestao = (exames, exameId, questao) => ({
  type: ctes.SELECIONAR_QUESTAO,
  payload: { exames, exameId, questao },
});

export const marcarAlternativa = (exames, exameId, questao, alternativa) => ({
  type: ctes.MARCAR_ALTERNATIVA,
  payload: { exames, exameId, questao, alternativa },
});

export const adicionarTag = (exames, exameId, questao, tag) => ({
  type: ctes.ADICIONAR_TAG,
  payload: { exames, exameId, questao, tag },
});

export const removerTag = (exames, exameId, questao, tagIndex) => ({
  type: ctes.REMOVER_TAG,
  payload: { exames, exameId, questao, tagIndex },
});

export const changeContent = (exames, exameId, questao, content) => ({
    type: ctes.CHANGE_CONTENT,
    payload: { exames, exameId, questao, content },
  });
  
export const adicionarExamTag = (exames, exameId, tag) => ({
  type: ctes.ADICIONAR_EXAM_TAG,
  payload: { exames, exameId, tag },
});

export const removerExamTag = (exames, exameId, tagIndex) => ({
  type: ctes.REMOVER_EXAM_TAG,
  payload: { exames, exameId, tagIndex },
});

export const editarAlternativa = (exames, exameId, questao, alternativa) => ({
  type: ctes.EDITAR_ALTERNATIVA,
  payload: { exames, exameId, questao, alternativa },
});

export const salvarQuestao = (exames, exameId, questao) => ({
  type: ctes.SALVAR_QUESTAO,
  payload: { exames, exameId, questao },
});

export const salvarQuestoes = (exames, questoes) => ({
  type: ctes.SALVAR_QUESTOES,
  payload: { exames, questoes },
});

export const setEditing = (editing) => ({
  type: ctes.SET_EDITING,
  payload: editing,
});

export const createExam = (exam) => ({
  type: ctes.CREATE_EXAM,
  payload: { exam },
});

export const createExamSuccess = (examId) => ({
  type: ctes.CREATE_EXAM_SUCCESS,
  payload: examId,
});

export const createExamFailure = (error) => ({
  type: ctes.CREATE_EXAM_FAILURE,
  payload: error,
});

export const getVideosLoaded = (examId) => ({
  type: ctes.GET_VIDEOS_LOADED,
  payload: examId,
});

export const getVideosLoadedSuccess = () => ({
  type: ctes.GET_VIDEOS_LOADED_SUCCESS,
  payload: false,
});

export const getVideosLoadedFailure = (error) => ({
  type: ctes.GET_VIDEOS_LOADED_FAILURE,
  payload: error,
});

export const updateExam = (exam, download) => ({
  type: ctes.UPDATE_EXAM,
  payload: { exam, download },
});

export const updateExamSuccess = (examId) => ({
  type: ctes.UPDATE_EXAM_SUCCESS,
  payload: examId,
});

export const updateExamFailure = (error) => ({
  type: ctes.UPDATE_EXAM_FAILURE,
  payload: error,
});

export const openTestUpdate = (examId, openId, openTestUserMean) => ({
  type: ctes.OPEN_TEST_UPDATE,
  payload: { examId, openTestUserMean, openId },
});

export const openTestUpdateSuccess = (res) => ({
  type: ctes.OPEN_TEST_UPDATE_SUCCESS,
  payload: res,
});

export const openTestUpdateFailure = (error) => ({
  type: ctes.OPEN_TEST_UPDATE_FAILURE,
  payload: error,
});

export const searchSelected = (
  educationType,
  tipoProva,
  tipoQuestao,
  provaIds,
  questaoTags,
  year,
  listOfMatrixObject,
  listOfMatrixHabilityObject,
  treeId,
  treeIdHabilities,
  page,
  limit,
  byBatch = false,
  searchStatement
) => ({
  type: ctes.SEARCH_SELECTED,
  payload: {
    educationType,
    tipoProva,
    tipoQuestao,
    provaIds,
    questaoTags,
    year,
    listOfMatrixObject,
    listOfMatrixHabilityObject,
    treeId,
    treeIdHabilities,
    page,
    limit,
    byBatch,
    searchStatement
  },
});

export const searchSelectedSuccess = (selected) => ({
  type: ctes.SEARCH_SELECTED_SUCCESS,
  payload: selected,
});

export const searchSelectedFailure = (error) => ({
  type: ctes.SEARCH_SELECTED_FAILURE,
  payload: error,
});

export const fetchAnoTrilhas = () => ({
  type: ctes.FETCH_ANO_TRILHAS,
});

export const fetchAnoTrilhasSuccess = (anos) => ({
  type: ctes.FETCH_ANO_TRILHAS_SUCCESS,
  payload: anos,
});

export const fetchAnoTrilhasFailure = (error) => ({
  type: ctes.FETCH_ANO_TRILHAS_FAILURE,
  payload: error,
});

export const updateResultRW = (resultRW, modifiedData, year) => ({
  type: ctes.UPDATE_RESULT_RW,
  payload: { resultRW, modifiedData, year }
});

export const updateResultRWSuccess = (resultRW) => ({
  type: ctes.UPDATE_RESULT_RW_SUCCESS,
  payload: resultRW
});

export const updateResultRWFailure = (error) => ({
  type: ctes.UPDATE_RESULT_RW_FAILURE,
  payload: error
});

export const fetchReadAndWriting = (grade, companyId, year) => ({
  type: ctes.FETCH_READ_AND_WRITING,
  payload: { grade, companyId, year }
});

export const fetchReadAndWritingSuccess = (data) => ({
  type: ctes.FETCH_READ_AND_WRITING_SUCCESS,
  payload: data,
});

export const fetchReadAndWritingFailure = (error) => ({
  type: ctes.FETCH_READ_AND_WRITING_FAILURE,
  payload: error,
});

export const fetchReadingAndWritingCSVData = () => ({
  type: ctes.FETCH_READING_AND_WRITING_DATA_CSV
});

export const fetchReadingAndWritingCSVDataSuccess = (data) => ({
  type: ctes.FETCH_READING_AND_WRITING_DATA_CSV_SUCCESS,
  payload: data,
});

export const fetchReadingAndWritingCSVDataFailure = (error) => ({
  type: ctes.FETCH_READING_AND_WRITING_DATA_CSV_FAILURE,
  payload: error,
});

export const fetchDisciplinasTrilhas = (ano) => ({
  type: ctes.FETCH_DISCIPLINAS_TRILHAS,
  payload: { ano },
});

export const fetchDisciplinasTrilhasSuccess = (disciplinas) => ({
  type: ctes.FETCH_DISCIPLINAS_TRILHAS_SUCCESS,
  payload: disciplinas,
});

export const fetchDisciplinasTrilhasFailure = (error) => ({
  type: ctes.FETCH_DISCIPLINAS_TRILHAS_FAILURE,
  payload: error,
});


export const fetchStudentsTrilhas = (ano, disciplina) => ({
  type: ctes.FETCH_STUDENTS_TRILHAS,
  payload: { ano, disciplina },
});

export const fetchStudentsTrilhasSuccess = (students) => ({
  type: ctes.FETCH_STUDENTS_TRILHAS_SUCCESS,
  payload: students,
});

export const fetchStudentsTrilhasFailure = (error) => ({
  type: ctes.FETCH_STUDENTS_TRILHAS_FAILURE,
  payload: error,
});

export const fetchUnidadesTematicas = (ano, disciplina) => ({
  type: ctes.FETCH_UNIDADES_TEMATICAS,
  payload: { ano, disciplina },
});

export const fetchUnidadesTematicasSuccess = (result) => ({
  type: ctes.FETCH_UNIDADES_TEMATICAS_SUCCESS,
  payload: result,
});

export const fetchUnidadesTematicasFailure = (error) => ({
  type: ctes.FETCH_UNIDADES_TEMATICAS_FAILURE,
  payload: error,
});

export const fetchResultTrilhas = (ano, disciplina) => ({
  type: ctes.FETCH_RESULT_TRILHAS,
  payload: { ano, disciplina },
});

export const fetchResultTrilhasSuccess = (result) => ({
  type: ctes.FETCH_RESULT_TRILHAS_SUCCESS,
  payload: result,
});

export const fetchResultTrilhasFailure = (error) => ({
  type: ctes.FETCH_RESULT_TRILHAS_FAILURE,
  payload: error,
});

export const fetchConsolidacao = (serie) => ({
  type: ctes.FETCH_CONSOLIDACAO,
  payload: { serie },
});

export const fetchConsolidacaoSuccess = (result) => ({
  type: ctes.FETCH_CONSOLIDACAO_SUCCESS,
  payload: result,
});

export const fetchConsolidacaoFailure = (error) => ({
  type: ctes.FETCH_CONSOLIDACAO_FAILURE,
  payload: error,
});
