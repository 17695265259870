/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Button, Popconfirm, Icon, Spin, message } from "antd";
import QuestionHeader from "../QuestionHeader";
import QuestionCard from "../QuestionCard";

import {
  deleteQuestion,
  marcarAlternativa,
  adicionarTag,
  removerTag,
  changeContent,
  adicionarExamTag,
  removerExamTag,
  selecionarQuestao,
  salvarQuestao,
  salvarQuestoes,
  setQuestions,
  setExamId,
  deleteTagExam,
  addTagExam,
  fetchClassifications,
  fetchChangePage,
  fetchExamMatrixEF,
  fetchExamMatrixEM,
  fetchExamHabilities
} from "../../../store/exams/actions";
import { deleteTag, addTag, addComment, addSolution } from "../../../store/questions/actions";
import history from "../../../routes/history";

import { QuestionList } from "./styles";
import {
  ButtonHolder,
  FloatButtons,
} from "../../../pages/styles/SelectQuestionsPage.styles";
import { ChildButton, FloatingMenu } from "react-floating-button-menu";
import * as Icons from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import SaveModal from "../../modal/SaveModal";
import QuestionsModal from "../../modal/QuestionsModal";
import WarningModal from "../../modal/WarningModal";
import { Row } from "../../../pages/styles/Page.styles";
import UpdateModal from "../../modal/UpdateModal";
import ContainerCard from "../../card/ContainerCard";
import { updateExam } from "../../../store/exams/services";
import { StyledInput, StyledButton } from "../../modal/styles/Modal.styles.js";
import {
  StyledTitle,
  Pagination,
} from "../../../pages/styles/ListExamsPage.styles";
import { StyledSelect } from "../../modal/styles/Modal.styles.js";
import { Box, Checkbox, Typography } from "@material-ui/core";

function QuestionBlock({
  marcarAlternativa,
  adicionarTag,
  removerTag,
  changeContent,
  adicionarExamTag,
  removerExamTag,
  selecionarQuestao,
  esvaziarCarrinho,
  salvarQuestao,
  salvarQuestoes,
  fetchChangePage,
  changePage,
  type,
  exams = [],
  editable = false,
  sufflable = false,
  onShuffle,
  isSelectable = false,
  totalSelected,
  view = false,
  onlyModal = false,
  showSave = true,
  editView = false,
  createView = true,
  loadQuestions = false,
  stillLoading = false,
  tags = [],
  selectedQuestions = [],
  examId = undefined,
  fetchClassifications,
  onDelete,
  updateSelected,
  updateCorrectAnswer,
  updateTitle = null,
  updateTagsExam = null,
  updateType,
  isSuperAdmin = false,
  removeModal,
  exam,
  answers,
  justView = false,
  user,
  addTagExam,
  deleteTag,
  addTag,
  deleteTagExam,
  similarStatement,
  questionsCompany,
  scholarship,
  treeIdEF,
  treeIdEM,
  treeIdHabilities,
  fetchExamMatrixEF,
  fetchExamMatrixEM,
  fetchExamHabilities,
  loggedUser,
  totalDocs,
  cartQuestions = [],
  disableSelect = false,
  blockibutton,
  addComment,
  addSolution
}) {
  let [tagsExam, setTagsExam] = useState(editView ? tags : []);
  const [questoesSelecionadas, setQuestoesSelecionadas] = useState([]);
  const [cartQuestoesSelecionadas, setCartQuestoesSelecionadas] = useState([]);
  const [updateComponent, setUpdateComponent] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filterByList, setFilterByList] = useState(true);
  const [justSelected, setJustSelected] = useState(false);
  const [displayQuestions, setDisplayQuestions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleSave, setIsModalVisibleSave] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalChecked, setTotalChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [load, setLoad] = useState(true);
  const [forceDescheck, setForceDescheck] = useState(undefined);
  const [inactiveQuestions, setInactiveQuestions] = useState([]);
  const [isVisible, setIsVisible] = useState(editView);
  const [prevStillLoading, setPrevStillLoading] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [questionNumber, setQuestionNumber] = useState({});
  const [questionKey, setQuestionKey] = useState({});
  const [title, setTitle] = useState(
    editView
      ? exam !== undefined && exam !== null && exam.Title !== ""
        ? exam.Title
        : ""
      : null
  );
  const [
    numberQuestionAndIdQuestion,
    setNumberQuestionAndIdQuestion,
  ] = useState([]);
  const [newId, setNewId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [merged, setMerged] = useState(false);
  const [subject, setSubject] = useState([]);
  const questionsEditing = useRef([]);

  const questionRef = useRef(null);

  const pageSize = 10;

  const dictIdNumber = {};
  const dictIdKey = {};
  const ExamStatus = {
    Active: 1, // aprovada
    Inactive: 2,
    Draft: 3 // rascunho
  };

  const disableComment = exam.Status !== ExamStatus.Draft;

  let subjects = [
    "Matemática",
    "Química",
    "Biologia",
    "História",
    "Física",
    "Geografia",
    "Filosofia",
    "Sociologia",
    "Língua Portuguesa",
    "Inglês",
    "Espanhol"
  ];

  const questionEditingMessage = "Existe uma questão em edição. Por favor, salve a edição da questão antes de realizar esta ação!"

  useEffect(() => {
    if (Array.isArray(exam)) {
      exam.forEach((examObj) => {
        if (Array.isArray(examObj.Questions)) {
          examObj.Questions.forEach((question) => {
            question.examId = examObj.Id;
            question.examTitle = examObj.Title;
          });
        }
      });
    }
  }, [exam]);

  useEffect(() => {
    if (Array.isArray(cartQuestions)) {
      cartQuestions.forEach((examObj) => {
        if (Array.isArray(examObj.Questions)) {
          examObj.Questions.forEach((question) => {
            question.examId = examObj.Id;
            question.examTitle = examObj.Title;
          });
        }
      });
    }
  }, [cartQuestions]);

  useEffect(() => {
    if (user && user.Role === "Teacher" && user.Subject !== null) {
      if (Array.isArray(user.Subject)) {
        setSubject(user.Subject);
      } else {
        setSubject(user.Subject.split(",").map((s) => s.trim()));
      }
    } else {
      if (exam && exam.Subject && subject.length === 0) {
        if (Array.isArray(exam.Subject)) {
          setSubject(exam.Subject);
        } else {
          setSubject(exam.Subject.split(",").map((s) => s.trim()));
        }
      }
    }
  }, [user, exam, subject]);

  function handleQuestionsEditing(editing, id) {
    const index = questionsEditing.current.findIndex((item) => item.id === id);

    if (index !== -1) {
      questionsEditing.current[index].editing = editing;
    } else {
      const newEditingObj = {
        id: id,
        editing: editing
      }
      questionsEditing.current.push(newEditingObj);
    }
  }

  function noQuestionEditing() {
    const allEditingFalse = questionsEditing.current.every(item => item.editing === false);
    return allEditingFalse;
  }

  function handleSubject(value) {
    setSubject(value);
  }

  function handleEdit() {
    setEdit(true);
  }

  function handleUpdateTitle(e) {
    setTitle(e.target.value);
    if (updateTitle) {
      updateTitle(e.target.value);
    }
  }

  const getQuestionsNumber = () => {
    let contentQuestion = questions.filter(
      (q) => q.question.Content.length > 0
    );
    let notContentQuestion = questions.filter(
      (q) => q.question.Content.length === 0
    );

    notContentQuestion.map((item, index) => {
      dictIdNumber[item.question.Id] = index + 1;
      dictIdKey[item.question.Id] = "Q" + index + 1;
    });

    contentQuestion.map((item, index) => {
      dictIdNumber[item.question.Id] = index + 1;
      dictIdKey[item.question.Id] = "C" + index + 1;
    });

    setQuestionNumber(dictIdNumber);
    setQuestionKey(dictIdKey);

    return dictIdNumber
  };

  useEffect(() => {
    getQuestionsNumber();
    setUpdateComponent(!updateComponent);
  }, [questions]);

  const toggleModalSave = () => {
    if (noQuestionEditing()) {
      if (examId) {
        setExamId(examId, true);
      } else {
        const query = new URLSearchParams(window.location.search);
        let examIdAux = query.get("examId");
        setExamId(examIdAux, true);
      }
  
      if (!editView) {
        if (questoesSelecionadas.length === 0 && cartQuestoesSelecionadas.length === 0) {
          setOpen(true);
        } else {
          setIsModalVisibleSave(!isModalVisibleSave);
        }
      } else {
        setIsModalVisibleSave(!isModalVisibleSave);
      }
    } else {
      message.error(questionEditingMessage);
    }
  };
  const toggleModal = () => {
    setSelected(selected);
    setIsModalVisible(!isModalVisible);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const qs = questions.filter((q) => q.question.Selected === true);
    setQuestoesSelecionadas(qs);

    let qsCart = [];
    console.log(cartQuestions);
    for (let cq of cartQuestions) qsCart = [...qsCart, ...cq.Questions];
    qsCart = qsCart.filter((q) => q.Selected === true);
    setCartQuestoesSelecionadas(qsCart);
  }, [updateComponent]);

  useEffect(() => {
    setPrevStillLoading(stillLoading);
    if (prevStillLoading === true && stillLoading === false) {
      window.location.reload();
    }
  }, [stillLoading]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    let fromUpload = query.get("fromUpload");
    if (fromUpload === "true") {
      setInfoModal(true);
    }
    if (view === true) {
      if (exams.length > 0) {
        organizeQuestions(exams);
      } else if (exams.Questions !== undefined && exams.Questions.length > 0) {
        let obj = exams;
        exams = [];
        exams.push(obj);
        organizeQuestions(exams);
      }
    } else {
      if (exams.length > 0) {
        organizeQuestions(exams);
      }
    }
  }, [exams]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    setNewId(query.get("examId"));
    console.log(edit);
    let block = history.block((targetLocation) => {
      if (
        targetLocation.pathname !== "/list-exams/list-questions" &&
        targetLocation.pathname !== "/classification" &&
        targetLocation.search !== "?save=True" &&
        history.location.pathname === "/list-exams/list-questions" &&
        history.location.search.includes("?examId=")
      ) {
        if (
          window.confirm(
            "Caso tenha feito alterações na prova, elas ainda não foram salvas. Deseja salvar as alterações?"
          )
        ) {
          let ques = [...questions];
          let questionList = [];
          const DRAFT = 3;
          questionList = ques.map((qs, index) => {
            let theAlternatives = [];
            if (qs.question.Alternatives !== undefined) {
              theAlternatives = qs.question.Alternatives.map((alter, index) => {
                return {
                  Description: alter.Description,
                  Correct: alter.Correct,
                  Order: alter.Order,
                };
              });
              let exists = inactiveQuestions.find(
                (e) => e.Id === qs.question.Id
              );
              let status = "Active";
              if (exists !== null && exists !== undefined) {
                status = "Inactive";
              }
              return {
                Id: qs.question.Id,
                Number: index + 1,
                Statement: qs.question.Statement,
                Alternatives: theAlternatives,
                Type:
                  qs.question.Type !== undefined
                    ? qs.question.Type.replace(" ", "")
                    : "MultipleChoice",
                Status: status,
                Tags: qs.question.Tags,
              };
            }
            return null;
          });

          let examModified = {
            Title: title || "Prova Criada Online",
            Questions: questionList,
            Tags: tagsExam,
            Status: exam.Status ? exam.Status : DRAFT,
            Subject: subject && subject.length ? subject.join(", ") : null,
          };
          updateExam(newId, examModified);
        }
      }
      return true;
    });

    return () => {
      block();
    };
  }, [title, newId, questions, tagsExam]);

  useEffect(() => {
    console.log("antes", changePage);
    fetchChangePage(false);
    console.log("depois", changePage);
  }, []);

  useEffect(() => {
    if (editView) {
      organizeQuestions(exams);
      handleCheckAllLoad();
    }
    setLoad(false);
  }, []);

  useEffect(() => {
    if (treeIdEF) return;
    fetchExamMatrixEF(loggedUser.CompanyId);
  }, [treeIdEF]);

  useEffect(() => {
    if (treeIdEM) return;
    fetchExamMatrixEM(loggedUser.CompanyId);
  }, [treeIdEM]);

  useEffect(() => {
    if (treeIdHabilities) return;
    fetchExamHabilities(loggedUser.CompanyId);
  }, [treeIdHabilities]);

  function handlePagination(page) {
    if (noQuestionEditing()) {
      questionRef.current.scrollIntoView({ behavior: "smooth" });
      setPagination({ ...pagination, current: page });
      if (!editView) {
        setDisplayQuestions(questions);
      }
    } else {
      message.error(questionEditingMessage);
    }
  }

  function handleShuffle(value) {
    let array = [...questions];
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * i);
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    if (value !== "enunciados") {
      for (let i = array.length - 1; i > 0; i--) {
        let alternatives = [...array[i].question.Alternatives];
        for (let j = alternatives.length - 1; j > 0; j--) {
          let x = Math.floor(Math.random() * j);
          let tempA = alternatives[j];
          alternatives[j] = alternatives[x];
          alternatives[x] = tempA;
        }
        array[i].question.Alternatives = alternatives;
      }
    }

    if (sufflable) {
      onShuffle(array);
    }
    setDisplayQuestions(array);
    setQuestions(array);
  }

  function handleRemoveQuestion() {
    let newArray = [];
    let questionId = localStorage.getItem("questionId");
    for (const element of questions) {
      if (element.question.Id !== questionId) {
        newArray.push(element);
      }
    }

    let theQuestions = extractQuestionsDelete(newArray);
    setSelected(theQuestions);
    onDelete(newArray);
    if (editView) {
      updateSelected(theQuestions);
    }
    setQuestions(questions);
    localStorage.removeItem("questionId");
  }

  function extractQuestionsDelete(array) {
    let newArray = [];
    if (array) {
      for (const element of array) {
        newArray.push(element.question);
      }
    }

    return newArray;
  }

  function extractQuestions(array) {
    let arrayResponse = [];
    if (array) {
      let arrayAux = [];
      let arrayTitleQuestion = [];
      const examQuestions = array.map((exam) => {
        if (exam.Questions !== null && exam.Questions !== undefined) {
          return exam.Questions.filter(
            (q) =>
              !arrayAux.includes(q.Id) &&
              !arrayTitleQuestion.includes(q.Statement)
          ).map((question) => {
            arrayTitleQuestion.push(question.Statement);
            arrayAux.push(question.Id);
            return { examId: exam.Id, question };
          });
        } else {
          return {};
        }
      });
      return [].concat(...examQuestions);
    }

    return arrayResponse;
  }

  function checkIdAndContent(array) {
    let questoes = array.map((q, idx) => {
      let questao = q.question.Content === undefined ? { ...q.question, Content: [] } : q.question
      questao = q.question.Id === null ? { ...questao, Id: idx } : questao
      return { ...q, question: questao }
    })

    return questoes
  }

  function organizeQuestions(array) {
    let newQuestions = extractQuestions(array);
    let onlyQuestions = checkIdAndContent(newQuestions).filter(
      (q) => q.question.Content.length === 0
    );
    let numberAndId = [];
    setDisplayQuestions(newQuestions);
    setQuestions(checkIdAndContent(newQuestions));

    const qn = getQuestionsNumber();
    if (onlyQuestions.length > 0) {
      for (var i = 0; i < onlyQuestions.length; i++) {
        if (onlyQuestions[i].question) {
          numberAndId.push({
            Number: qn[onlyQuestions[i].question["Id"]],
            Id: onlyQuestions[i].question["Id"],
          });
        }
      }
    }

    setNumberQuestionAndIdQuestion(numberAndId);

    if (pagination.total === undefined) {
      setPagination({
        current: 1,
        total: newQuestions.length,
        pageSize: pageSize,
      });
    } else if (pagination.total !== newQuestions.length) {
      let current = 1;

      if (pagination.current <= newQuestions.length / pageSize + 1) {
        current = pagination.current;
      }

      setPagination({
        current: current,
        total: newQuestions.length,
        pageSize: pageSize,
      });
    } else {
      setPagination({
        current: pagination.current,
        total: newQuestions.length,
        pageSize: pageSize,
      });
    }
  }

  function handleCheckbox(checked, question) {
    setForceDescheck(undefined);
    if (checked) {
      const newInactiveQuestions = inactiveQuestions.filter(
        (sel) => sel.Id !== question.Id
      );
      setInactiveQuestions(newInactiveQuestions);
      setSelected([...selected, question]);
      updateSelected([...selected, question]);
    } else {
      const newSelected = selected.filter((sel) => sel.Id !== question.Id);
      setSelected(newSelected);
      updateSelected(newSelected);
      setInactiveQuestions([...inactiveQuestions, question]);
    }
  }

  const selecionarQuestoes = (questoes, checked, listaDeQuestoes = []) => {
    for (const el of listaDeQuestoes) {
      questoes[el].question.Selected = checked;
    }
    return questoes;
  };

  const checkListOfQuestions = (checked, indices) => {
    const questoes = selecionarQuestoes(questions, checked, indices);
    setUpdateComponent(!updateComponent);
    salvarQuestoes(exams, questoes);
  };

  function handleCheck(checked) {
    const todosIndices = [...Array(questions.length).keys()];
    checkListOfQuestions(checked, todosIndices);
    setTotalChecked(checked);
  }

  function handleCheckAll() {
    const checked = !totalChecked;
    handleCheck(checked);
  }

  function handleDesCheckAll() {
    const checked = false;
    handleCheck(checked);
  }

  function handleCheckAllLoad() {
    const allQuestions = displayQuestions.map((item) => {
      return item.question;
    });

    setTotalChecked(true);
    setSelected(allQuestions);
    updateSelected(allQuestions);
  }

  function onCancel() {
    setIsModalVisible(false);
  }

  function onCancelSave() {
    setIsModalVisibleSave(false);
  }

  function onRandom(number) {
    const maxNumber = number > questions.length ? questions.length : number;

    let randomIndexes = [];
    let removeIndexes = [...Array(questions.length).keys()];
    while (randomIndexes.length < maxNumber) {
      const r = Math.floor(Math.random() * questions.length);
      if (randomIndexes.indexOf(r) === -1) {
        randomIndexes.push(r);

        const removeIndex = removeIndexes.indexOf(r);
        removeIndexes.splice(removeIndex, 1);
      }
    }

    checkListOfQuestions(false, removeIndexes);
    checkListOfQuestions(true, randomIndexes);
  }

  useEffect(() => {
    if (isSelectable) {
      totalSelected(selected);
    }
  }, [selected]);

  function closeModal() {
    const allQuestions = questions.map((item) => {
      return item.question;
    });
    removeModal(allQuestions);
    updateSelected(allQuestions);
    setSelected(allQuestions);
    setIsVisible(false);
  }

  function columnToLetter(column) {
    let temp;
    let letter = "";
    while (column > 0) {
      temp = (column - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      column = (column - temp - 1) / 26;
    }
    return letter;
  }

  const fundirQuestoes = (arr, indexA, indexB) => {
    arr[indexA].question.Alternatives = [
      ...arr[indexA].question.Alternatives,
      ...arr[indexB].question.Alternatives,
    ];
    arr[indexA].question.Alternatives.forEach((a, index) => {
      a.Order = columnToLetter(index + 1);
    });
    arr[indexA].question.Statement =
      '<span style="color: #000000;font-size: 21px;font-family: Arial;">' +
      arr[indexA].question.Statement +
      arr[indexB].question.Statement +
      "</span>";

    if (
      arr[indexA].question.Type === "Dissertative" &&
      arr[indexB].question.Type === "Multiple Choice"
    ) {
      arr[indexA].question.Type = "Multiple Choice";
    } else if (
      arr[indexA].question.Content.length > 0 &&
      arr[indexB].question.Alternatives.length > 0
    ) {
      arr[indexA].question.Type = "Multiple Choice";
      arr[indexA].question.Content = [];
    } else if (
      arr[indexA].question.Content.length > 0 &&
      arr[indexB].question.Alternatives.length === 0 &&
      arr[indexB].question.Content.length === 0
    ) {
      arr[indexA].question.Type === "Dissertative";
      arr[indexA].question.Content = [];
    }

    if (indexB > -1) {
      arr.splice(indexB, 1);
    }
    return arr;
  };

  const adicionarQuestoes = (arr, index) => {
    arr.splice(index + 1, 0, {
      examId: arr[index].examId,
      question: {
        Alternatives: [],
        AnswerGPT: null,
        Content: [],
        ExamType: "Escola",
        Id: getRandomInt(10000),
        Instructions: null,
        IsVideo: false,
        Metadata: null,
        Number: arr[index].question.Number + 1,
        ParentContent: [],
        ParentStatements: null,
        Scholarship: arr[index].question.Scholarship,
        Statement: "",
        Tags: [],
        Type: "Dissertative",
        UpdateMatrix: false,
        VideoUrl: null,
        score: 0
      }
    });

    for (let qidx = 0; qidx < arr.length; qidx++) {
      arr[qidx].question.Number = qidx + 1;
    }

    return arr;
  };

  function mergeQuestions(nA, nB) {
    if (noQuestionEditing()) {
      setUpdateComponent(!updateComponent);

      const questoes = fundirQuestoes(questions, nA, nB);
      salvarQuestoes(exams, questoes);
    } else {
      message.error(questionEditingMessage);
    }
  }

  function addQuestions(n) {
    if (noQuestionEditing()) {
      setUpdateComponent(!updateComponent);

      const questoes = adicionarQuestoes(questions, n);
      salvarQuestoes(exams, questoes);
    } else {
      message.error(questionEditingMessage);
    } 
  }

  function applyAnswers(rawGabarito) {
    let multipleChoiceQuestions = questions.filter((q) => q.question.Alternatives.length > 0)
    const gabarito = rawGabarito ? handleAnswers(rawGabarito) : [];
    const questoes = questions
    const la = gabarito.length;
    questoes.map((q, idx) => {
      multipleChoiceQuestions.map((mcq, mcqIndex) => {
        if (q.question.Id === mcq.question.Id) {
          if (gabarito && mcqIndex < la) {
            if (q.question.Alternatives) {
              questoes[idx].question.Alternatives = q.question.Alternatives.map((alt) => {
                if (alt.Order === gabarito[mcqIndex]) {
                  alt.Correct = true;
                } else {
                  alt.Correct = false;
                }

                return alt;
              });
            }
          } else {
            q.question.Alternatives = q.question.Alternatives.map((alt) => {
              alt.Correct = false;
              return alt;
            });
          }
        }
      })
    })

    setUpdateComponent(!updateComponent);
    salvarQuestoes(exams, questoes);
  }

  const filterGabarito = (gabaritoStr) => {
    let matches;
    let alternatives = [];
    gabaritoStr = gabaritoStr.toUpperCase();
    while ((matches = /(\d*)[^0-9]*([A-EXa-ex])/g.exec(gabaritoStr)) !== null) {
      let index = parseInt(matches[1]) > 0 ? parseInt(matches[1]) - 1 : -1;
      let alt = matches[2].toUpperCase();
      alternatives[index] = index >= 0 ? alt : undefined;
      gabaritoStr = gabaritoStr.replace(matches[0], "");
    }

    return alternatives;
  };

  const handleAnswers = (gabaritoStr) => {
    let alternatives = [];

    if (/\d/.test(gabaritoStr)) {
      alternatives = filterGabarito(gabaritoStr);
    } else {
      let matches = gabaritoStr.match(/([A-EX*a-ex])/g);
      alternatives =
        matches !== null ? matches.map((letter) => letter.toUpperCase()) : [];
    }

    return alternatives;
  };

  const getAnswerGPT = () => {
    return questions.map((question) => {
      return ["A", "B", "C", "D", "E"].includes(question.question.AnswerGPT)
        ? question.question.AnswerGPT
        : "x";
    });
  };

  const downloadDefault = () => {
    const ext = title.substr(title.lastIndexOf(".") + 1);
    window.open(
      `https://simulie-api-prod.s3.sa-east-1.amazonaws.com/${examId}.${ext}`
    );
  };

  const handleMarcarAlternativa = (exameId, questao, alternativa) => {
    marcarAlternativa(exams, exameId, questao, alternativa);
  };

  const handleAdicionarTag = (exameId, questao, tag) => {
    adicionarTag(exams, exameId, questao, tag);
  };

  const handleRemoverTag = (exameId, questao, tag) => {
    removerTag(exams, exameId, questao, tag);
  };

  const handleChangeContent = (exameId, questao, content) => {
    changeContent(exams, exameId, questao, content);
  };

  const handleSelecionarQuestao = (exameId, questao) => {
    selecionarQuestao(exams, exameId, questao);
    setUpdateComponent(!updateComponent);
  };

  const handleSalvar = (exameId, questao) => {
    salvarQuestao(exams, exameId, questao);
  };

  const handleAddComment = (questionId, comment) => {
    addComment(examId, questionId, comment)
  };

  const handleAddSolution = (questionId, solution) => {
    addSolution(examId, questionId, solution)
  };

  const handleDisplaySelected = (event) => {
    if (event.target.checked) {
      setDisplayQuestions(questoesSelecionadas);
      setPagination({
        current: 1,
        total: questoesSelecionadas.length,
        pageSize: pageSize,
      });
    } else {
      setDisplayQuestions(questions);
      setPagination({
        current: 1,
        total: questions.length,
        pageSize: pageSize,
      });
    }
  };

  const paginate = (elements) => {
    return elements.slice(
      (pagination.current - 1) * pagination.pageSize,
      pagination.current * pagination.pageSize
    );
  };

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max).toString();
  };

  return (
    <div style={{ width: "100%" }} ref={questionRef}>
      <Spin spinning={load}>
        {merged && setMerged(false)}
        {editView && infoModal && (
          <WarningModal
            visible={isVisible}
            onCancel={closeModal}
            message={
              "Algumas imagens podem demorar um pouco a serem exibidas pelo fato de estarem sendo carregadas!"
            }
          />
        )}
        {onlyModal === false && !justView && (
          <>
            <ContainerCard>
              <QuestionHeader
                changePage={changePage}
                onlyModal={onlyModal}
                total={questions.length}
                selecionadas={
                  editView ? selectedQuestions.length : selected.length
                }
                editable={editable}
                disableGabarito={stillLoading}
                onDesCheck={handleDesCheckAll}
                onCheck={handleCheckAll}
                onRandom={onRandom}
                editView={editView}
                createView={createView}
                examId={examId}
                fetchClassifications={fetchClassifications}
                handleApplyAnswers={applyAnswers}
                getAnswerGPT={getAnswerGPT}
                handleEdit={handleEdit}
                onAddComment={handleAddComment}
                onAddSolution={handleAddSolution}
                scholarship={scholarship}
                disableComment={disableComment}
              />
              {editView && (
                <>
                  <Row>
                    <div className={"col-sm-12"}>
                      <StyledTitle>Nome da Prova:</StyledTitle>
                      <StyledInput
                        type="text"
                        value={
                          title != null
                            ? title
                            : exam !== undefined &&
                              exam !== null &&
                              exam.Title !== ""
                              ? exam.Title
                              : ""
                        }
                        onInput={handleUpdateTitle}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className={"col-sm-12"}>
                    {user &&
                      (user.Role === "Super" ||
                      user.Role === "Master") &&
                      <>
                        <StyledTitle>Disciplina:</StyledTitle>
                        <StyledSelect
                          mode="multiple"  
                          className='select-input'
                          value={subject}
                          onChange={handleSubject}
                          showSearch
                          style={{ width: '100%' }}
                          placeholder={'Disciplina'}
                          allowClear
                        >
                          {subjects.map((option, index) => (
                            <Option key={index} value={option}>
                              {option}
                            </Option>
                          ))}     
                        </StyledSelect>
                      </>
                    }
                    </div>
                  </Row>
                  <Row>
                    <div>
                      {user &&
                        (user.Id === "65bf06d0-d660-42d0-af16-f72e8887e20c" ||
                          user.Id === "5d192e43-2bcd-478b-ab70-3681e7c64ff6") ? ( // user recognition@simulie.com
                        <StyledButton
                          style={{ marginTop: "2em" }}
                          onClick={() => downloadDefault()}
                        >
                          Baixar Prova
                        </StyledButton>
                      ) : null}
                    </div>
                  </Row>
                </>
              )}
            </ContainerCard>
            <br />
          </>
        )}
        {onlyModal === true &&
          questions.length > 0 &&
          paginate(questions).map((item, i) => {
            let typeContent = item.question.Content.length > 0 ? "C" : "Q";
            let numberQuestion = questionNumber[item.question.Id];
            let keyQuestion = typeContent + questionKey[item.question.Id];
            return (
              !isNaN(numberQuestion) && (
                <QuestionList key={keyQuestion}>
                  <QuestionCard
                    updateComponent={updateComponent}
                    setUpdateComponent={setUpdateComponent}
                    disableSelect={disableSelect}
                    disableEdicao
                    blockibutton={blockibutton}
                    examId={item.examId}
                    question={item.question}
                    onChdeck={handleCheckbox}
                    numberQuestion={numberQuestion}
                    onlyModal={onlyModal}
                    forceDescheck={forceDescheck}
                    marcarAlternativa={handleMarcarAlternativa}
                    adicionarTag={handleAdicionarTag}
                    removerTag={handleRemoverTag}
                    selecionarQuestao={handleSelecionarQuestao}
                    salvarQuestao={handleSalvar}
                    scholarship={scholarship}
                    treeIdEF={treeIdEF}
                    treeIdEM={treeIdEM}
                    treeIdHabilities={treeIdHabilities}
                    disableComment
                    handleQuestionsEditing={handleQuestionsEditing}
                  />
                </QuestionList>
              )
            );
          })}
        {onlyModal === false &&
          editView &&
          questions.length > 0 &&
          paginate(questions).map((item, i, elements) => {
            let typeContent = item.question.Content.length > 0 ? "C" : "Q";
            let numberQuestion = questionNumber[item.question.Id];
            let keyQuestion = typeContent + questionKey[item.question.Id];
            return (
              !isNaN(numberQuestion) && (
                <QuestionList key={keyQuestion}>
                  <QuestionCard
                    disableSelect
                    updateComponent={updateComponent}
                    setUpdateComponent={setUpdateComponent}
                    disableBotaoEdicao={
                      user.Id !== "a597fe39-826c-424e-b04a-09bb11949fcf" && ( // bancodequestoes@simulie.com
                        stillLoading || 
                        item.question.ExamType === 'ENEM' ||
                        item.question.ExamType === 'Vestibulares' ||
                        item.question.ExamType === 'VestibularesCE'
                      )
                    }
                    disableEdicao={user.Id !== "a597fe39-826c-424e-b04a-09bb11949fcf" && ( // bancodequestoes@simulie.com
                        stillLoading ||
                        item.question.ExamType === 'ENEM' ||
                        item.question.ExamType === 'Vestibulares' ||
                        item.question.ExamType === 'VestibularesCE'
                      )
                    }
                    examId={item.examId}
                    question={item.question}
                    marginTop={"0px"}
                    numberQuestion={numberQuestion}
                    onlyModal={false}
                    merged={merged}
                    forceDescheck={forceDescheck}
                    adicionarTag={handleAdicionarTag}
                    removerTag={handleRemoverTag}
                    contentOptions={numberQuestionAndIdQuestion}
                    changeContent={handleChangeContent}
                    showContent={
                      user &&
                      (user.Id === "65bf06d0-d660-42d0-af16-f72e8887e20c" ||
                        user.Id === "5d192e43-2bcd-478b-ab70-3681e7c64ff6" ||
                        user.Id === "6b14ee60-2c78-4277-82bf-9669759efddc" ||
                        user.Id === "bbc89e1a-cf89-4b01-9fd9-07564db0b467" ||
                        user.Id === "0dcebabf-653d-4840-8961-e2e165a0ae12" ||
                        user.Id === "87b7b4d2-08df-4f7c-8f50-3099e9c46e48" ||
                        user.Id === "a597fe39-826c-424e-b04a-09bb11949fcf" ||
                        user.Id === "453aaac5-6fb3-4ce4-b1bb-bd951032b10c" ||
                        user.Id === "4ccf0619-370e-4288-bb61-15a9f3427b6f" ||
                        user.Id === "a575f3e3-935d-4c48-8058-b39ef28a55bd")
                    }
                    marcarAlternativa={handleMarcarAlternativa}
                    selecionarQuestao={handleSelecionarQuestao}
                    salvarQuestao={handleSalvar}
                    onAddComment={handleAddComment}
                    onAddSolution={handleAddSolution}
                    scholarship={scholarship}
                    treeIdEF={treeIdEF}
                    treeIdEM={treeIdEM}
                    treeIdHabilities={treeIdHabilities}
                    disableComment={disableComment}
                    handleQuestionsEditing={handleQuestionsEditing}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    {i + 1 + (pagination.current - 1) * pageSize <
                      questions.length && (
                        <>
                          <Popconfirm
                            arrowPointAtCenter
                            cancelText="Não"
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            okText="Sim"
                            okType="danger"
                            onConfirm={() =>
                              mergeQuestions(
                                i + (pagination.current - 1) * pageSize,
                                i + 1 + (pagination.current - 1) * pageSize
                              )
                            }
                            placement="topRight"
                            title="Tem certeza?"
                          >
                            <StyledButton
                              style={{ marginTop: "2em" }}
                              disabled={stillLoading}
                            >
                              Fundir{" "}
                              {elements[i].question.Content.length > 0
                                ? "o conteúdo"
                                : "a questão"}{" "}
                              acima com{" "}
                              {elements[i + 1] &&
                                elements[i + 1].question.Content.length > 0
                                ? "o conteúdo"
                                : "a questão"}{" "}
                              abaixo
                            </StyledButton>
                          </Popconfirm>
                          <StyledButton
                            style={{ marginTop: "2em" }}
                            disabled={stillLoading}
                            onClick={() => addQuestions(i + (pagination.current - 1) * pageSize)}
                          >
                            Adicionar questão
                          </StyledButton>
                        </>
                      )}
                  </div>
                </QuestionList>
              )
            );
          })}
        {onlyModal === false && createView && (
          <Grid>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={12} lg={4}>
                <Box display="flex" justifyContent="center">
                  <Typography>
                    <Checkbox
                      onChange={handleDisplaySelected}
                      color="primary"
                    />
                    Selecionadas {questoesSelecionadas.length} de{" "}
                    {questions.length}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Box display="flex" justifyContent="center">
                  <Typography>
                    {/* Carregadas {questions.length} de {questionsCompany} */}
                    {questions.length} questões carregadas
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {displayQuestions.length > 0 &&
              paginate(displayQuestions).map((item, i) => {
                let typeContent = item.question.Content.length > 0 ? "C" : "Q";
                let numberQuestion = questionNumber[item.question.Id];
                let keyQuestion = typeContent + questionKey[item.question.Id];
                return (
                  !isNaN(numberQuestion) && (
                    <QuestionList key={keyQuestion}>
                      <QuestionCard
                        updateComponent={updateComponent}
                        setUpdateComponent={setUpdateComponent}
                        disableBotaoEdicao
                        disableEdicao
                        examId={item.examId}
                        question={item.question}
                        onCheck={handleCheckbox}
                        numberQuestion={numberQuestion}
                        onlyModal={false}
                        forceDescheck={forceDescheck}
                        marcarAlternativa={handleMarcarAlternativa}
                        selecionarQuestao={handleSelecionarQuestao}
                        adicionarTag={handleAdicionarTag}
                        removerTag={handleRemoverTag}
                        salvarQuestao={handleSalvar}
                        disableComment={disableComment}
                        handleQuestionsEditing={handleQuestionsEditing}
                      />
                    </QuestionList>
                  )
                );
              })}
          </Grid>
        )}
        {filterByList && Object.entries(pagination).length !== 0 && (
          <Pagination
            current={Number(pagination.current)}
            total={Number(pagination.total)}
            pageSize={Number(pagination.pageSize)}
            onChange={handlePagination}
          />
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Atenção!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Você não selecionou nenhuma questão
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {loadQuestions && createView && (
          <SaveModal
            inactiveQuestions={inactiveQuestions}
            isSuperAdmin={isSuperAdmin}
            onShuffle={onShuffle}
            editView={false}
            selected={selected}
            examTags={tagsExam}
            realQuestion={questions}
            questions={questoesSelecionadas.map((q) => q.question)}
            cartQuestions={cartQuestoesSelecionadas}
            removeQuestion={handleSelecionarQuestao}
            removeAllQuestions={handleDesCheckAll}
            visible={isModalVisibleSave}
            toggle={toggleModalSave}
            onCancel={onCancelSave}
          />
        )}
        {loadQuestions && editView && (
          <UpdateModal
            inactiveQuestions={inactiveQuestions}
            isSuperAdmin={isSuperAdmin}
            tags={tagsExam}
            title={title}
            onShuffle={onShuffle}
            editView={editView}
            selected={selected}
            examTags={tagsExam}
            realQuestion={questions}
            questions={
              editView ? questions : questoesSelecionadas.map((q) => q.question)
            }
            visible={isModalVisibleSave}
            toggle={toggleModalSave}
            onCancel={onCancelSave}
            subjectSelected={subject}
          />
        )}
        {loadQuestions && (
          <QuestionsModal
            createView={createView}
            editView={editView}
            editViewonShuffle={handleShuffle}
            selected={selected}
            onDelete={handleRemoveQuestion}
            onShuffle={handleShuffle}
            questions={questoesSelecionadas.map((q) => q.question)}
            visible={isModalVisible}
            toggle={toggleModal}
            onCancel={onCancel}
          />
        )}
        {loadQuestions && createView && !onlyModal && showSave && (
          <FloatButtons>
            <ButtonHolder>
              <FloatingMenu
                slideSpeed={500}
                direction="right"
                spacing={8}
                isOpen={isOpen}
              >
                <ChildButton
                  icon={<Icons.Save style={{ fontSize: 20, color: "white" }} />}
                  background="green"
                  onClick={toggleModalSave}
                  size={60}
                />
              </FloatingMenu>
            </ButtonHolder>
          </FloatButtons>
        )}
        {loadQuestions && editView && !onlyModal && showSave && (
          <FloatButtons>
            <ButtonHolder>
              <FloatingMenu
                slideSpeed={500}
                direction="right"
                spacing={8}
                isOpen={isOpen}
              >
                <ChildButton
                  icon={<Icons.Save style={{ fontSize: 20, color: "white" }} />}
                  background="green"
                  onClick={toggleModalSave}
                  size={60}
                />
              </FloatingMenu>
            </ButtonHolder>
          </FloatButtons>
        )}
      </Spin>
    </div>
  );
}

QuestionBlock.propTypes = {
  changePage: PropTypes.any,
  justSelected: PropTypes.bool,
  exams: PropTypes.any,
  exam: PropTypes.any,
  editable: PropTypes.bool,
  justView: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  sufflable: PropTypes.bool,
  showSave: PropTypes.bool,
  isSelectable: PropTypes.bool,
  onShuffle: PropTypes.func,
  onCheck: PropTypes.func,
  onDesCheck: PropTypes.func,
  setExamId: PropTypes.func,
  addTagExam: PropTypes.func,
  deleteTagExam: PropTypes.func,
  addTag: PropTypes.func,
  deleteTag: PropTypes.func,
  resolutions: PropTypes.array,
  tags: PropTypes.array,
  loading: PropTypes.bool,
  changeTypeInline: PropTypes.bool,
  onDelete: PropTypes.func,
  deleteQuestion: PropTypes.func,
  updateSelected: PropTypes.func,
  removeModal: PropTypes.func,
  updateType: PropTypes.func,
  similarStatement: PropTypes.array,
  checkQuestionSelected: PropTypes.func,
  fetchClassifications: PropTypes.func,
  fetchChangePage: PropTypes.func,
  scholarship: PropTypes.string,
  treeIdEF: PropTypes.string,
  treeIdEM: PropTypes.string,
  treeIdHabilities: PropTypes.string,
  fetchExamMatrixEF: PropTypes.func,
  fetchExamMatrixEM: PropTypes.func,
  fetchExamHabilities: PropTypes.func,
  loggedUser: PropTypes.object,
  cartQuestions: PropTypes.array,
  addComment: PropTypes.func,
  addSolution: PropTypes.func,
};

const mapStateToProps = ({ exams, users, auth }) => ({
  changePage: exams.changePage,
  user: users.infoUser,
  loggedUser: auth.loggedUser,
  treeIdEF: exams.treeIdEF,
  treeIdEM: exams.treeIdEM,
  treeIdHabilities: exams.treeIdHabilities,
  totalDocs: exams.totalDocs,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteQuestion,
      setQuestions,
      marcarAlternativa,
      adicionarTag,
      removerTag,
      changeContent,
      adicionarExamTag,
      removerExamTag,
      selecionarQuestao,
      salvarQuestao,
      salvarQuestoes,
      setExamId,
      deleteTag,
      addTag,
      addTagExam,
      deleteTagExam,
      fetchClassifications,
      fetchChangePage,
      fetchExamMatrixEF,
      fetchExamMatrixEM,
      fetchExamHabilities,
      addComment,
      addSolution
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBlock);
