import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { Col, Row } from "../../components/table/styles/Table.styles";
import { Title } from "../../components/form/styles/Form.styles";
import { Page } from "../styles/Page.styles";
import { Button } from "../../components-refactor/button/Button";
import { Select } from "../../components-refactor/select/Select";

import { getClasses } from "../../store/auth/actions";
import { readGrade, updateGrade } from "../../store/grades/actions";
import { infoUser } from "../../store/users/actions";

const useStyles = makeStyles((theme) => ({
  text: {
    width: "90vw",
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(96),
    },
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const StartProfessor = (props) => {
  const classes = useStyles();
  const [examId, setExamId] = useState(undefined);
  const [studentId, setStudentId] = useState(undefined);

  useEffect(() => {
    props.getClasses();
    props.infoUser();
  }, []);

  useEffect(() => {
    if (props.user && props.user.Role === "Master") {
        props.readGrade();
    }
  }, [props.user]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (examId && studentId) {
      window.location.href = `/professor/exam?examId=${examId}&studentId=${studentId}`;
    } else {
      window.alert("Selecione todos os campos");
    }
  };

  const buildClassesOptions = (options) =>
    options && options.constructor === Array
      ? options.map((option) => ({
          id: option,
          title: option,
        }))
      : [];

  const buildGradeOptions = (options) =>
    options && options.constructor === Array
      ? options.map((option) => ({
          id: option.Id,
          title: option.Title || option.Name,
        }))
      : [];

  const handleClasses = (evt) => {
    props.readGrade({
      turma: evt.target.value,
    });
  };

  const handleExams = (evt) => {
    setExamId(evt.target.value);
  };

  const handleStudents = (evt) => {
    setStudentId(evt.target.value);
  };

  return (
    <Page>
      <Row center>
        <Col center size={5}>
          <Title>Iniciar Prova</Title>
        </Col>
      </Row>

      <br />

      <form onSubmit={handleSubmit}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          {props.user && props.user.Role === "Master" ? null : <Select
            label="Selecione a Turma"
            loaded={props.classes}
            options={buildClassesOptions(props.classes)}
            className={classes.text}
            onInputChange={handleClasses}
          />}
          <Select
            label="Selecione a Prova"
            loaded={props.grade && props.grade.Exams}
            options={buildGradeOptions((props.grade && props.grade.Exams) || [])}
            className={classes.text}
            onInputChange={handleExams}
          />
          <Select
            label="Selecione o Aluno"
            loaded={props.grade && props.grade.Students}
            options={buildGradeOptions((props.grade && props.grade.Students) || [])}
            className={classes.text}
            onInputChange={handleStudents}
          />
          <Button type="submit" label="Iniciar Prova" />
        </Grid>
      </form>
    </Page>
  );
};

const mapStateToProps = ({ users, grades, auth }) => ({
  user: users.infoUser,
  grade: grades.grade,
  classes: auth.classes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      infoUser,
      getClasses,
      readGrade,
      updateGrade,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StartProfessor);
