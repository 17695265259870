/* eslint-disable */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchOutput } from '../../store/outputs/actions'
import history from '../../routes/history'
import { Button, Modal, Spin } from 'antd'
import { QuestionsModalStyle, StyledButton } from './styles/Modal.styles'
import { Buttons } from '../../pages/styles/ReviseQuestionsPage.styles'
import { Row } from '../../pages/styles/Page.styles'
import { Grid, useTheme, useMediaQuery } from '@mui/material';

import { setExamId, downloadExam } from '../../store/exams/actions'
import { createExam, finishExam, updateExam } from '../../store/exams/services'
import { useEffect, useState } from 'react'
import { NH_SUPER_ID } from '../../utils/constants'

import SelectCompanies from '../input/SelectCompanies'

const UpdateModal = ({
  onCancel,
  selected,
  questions,
  visible,
  examTags,
  downloadFile,
  id,
  onShuffle,
  editView,
  setExamId,
  isSuperAdmin,
  realQuestions,
  inactiveQuestions,
  tags,
  title,
  user,
  subjectSelected
}) => {
  const [ques, setQues] = useState([])
  const [loading, setLoading] = useState(false)
  const [escolas, setEscolas] = useState([])
  const [subject, setSubject] = useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setQues(questions)
  }, [questions])

  useEffect(()=>{
    if (user && user.Role === "Teacher" && user.Subject !== null){
      setSubject(user.Subject);
    }
  },[user, visible])

  // function handleDownloadAction (examId) {
  //   let parametro = '/false'
  //   const host = 'https://api.simulie.com'
  //   const fileName = examId
  //   const link = document.createElement('a')
  //   link.href = `${host}/exam/download/${examId}${parametro}`
  //   link.target = '_blank'
  //   link.download = fileName
  //   document.body.append(link)
  //   link.click()
  //   link.remove()
  // }

  // function handleDownloadResolutionAction (examId) {
  //   const host = 'https://api.simulie.com'
  //   const fileName = examId
  //   const link = document.createElement('a')
  //   link.href = `${host}/exam/download/resolution-with-answers/${examId}`
  //   link.target = '_blank'
  //   link.download = fileName
  //   document.body.append(link)
  //   link.click()
  //   link.remove()
  // }

  // function handleDownloadResolutionCompleteAction (examId) {
  //   const host = 'https://api.simulie.com'
  //   const fileName = examId
  //   const link = document.createElement('a')
  //   link.href = `${host}/exam/download/resolution-without-answers/${examId}`
  //   link.target = '_blank'
  //   link.download = fileName
  //   document.body.append(link)
  //   link.click()
  //   link.remove()
  // }

  function handleSelectCompany (value) {
    console.log('escolas selecionadas', value)
    setEscolas(value)
  }

  const newExam = () => {
    const array = [...questions]
    setQues(array)
    let questionList = []
    if (editView) {
      questionList = ques.map((qs, index) => {
        let theAlternatives = []
        if (qs.question.Alternatives !== undefined) {
          theAlternatives = qs.question.Alternatives.map((alter, index) => {
            return {
              Description: alter.Description,
              Correct: alter.Correct,
              Order: alter.Order
            }
          })
        }

        let exists = inactiveQuestions.find(e => e.Id === qs.question.Id)
        let status = 'Active'
        if (exists !== null && exists !== undefined) {
          status = 'Inactive'
        }
        return {
          Id: qs.question.Id,
          Number: index + 1,
          Statement: qs.question.Statement,
          Content: qs.question.Content,
          Alternatives: theAlternatives,
          Type: qs.question.Type !== undefined ? qs.question.Type.replace(' ', '') : 'MultipleChoice',
          Status: status,
          Tags: qs.question.Tags,
          UpdateMatrix: qs.question.UpdateMatrix ? qs.question.UpdateMatrix : false,
        }
      })
    } else {
      questionList = ques.map((qs, index) => {
        let theAlternatives = []
        if (qs.Alternatives !== undefined) {
          theAlternatives = qs.Alternatives.map((alter, index) => {
            return {
              Description: alter.Description,
              Correct: alter.Correct,
              Order: alter.Order
            }
          })
        }

        let exists = inactiveQuestions.find(e => e.Id === qs.Id)
        let status = 'Active'
        if (exists !== null && exists !== undefined) {
          status = 'Inactive'
        }
        return {
          Id: qs.Id,
          Number: index + 1,
          Alternatives: theAlternatives,
          Type: qs.Type !== undefined ? qs.Type.replace(' ', '') : 'MultipleChoice',
          Status: status,
          Tags: qs.Tags,
          UpdateMatrix: true,
        }
      })
    }

    if (editView) {
      if (id === null) {
        return {
          'Title': title || 'Prova Criada Online',
          'Questions': questionList,
          'Tags': examTags,
          'Subject': subject || subjectSelected.length ? subjectSelected.join(", ") : null,
        }
      } else {
        return {
          'Title': title || 'Prova Criada Online',
          'Questions': questionList,
          'Tags': examTags,
          'Subject': subject || subjectSelected.length ? subjectSelected.join(", ") : null,
        }
      }
    } else {
      return {
        'Title': title || 'Prova Criada Online',
        'Questions': questionList,
        'Tags': examTags,
        'Subject': subject || subjectSelected.length ? subjectSelected.join(", ") : null,
      }
    }
  }

  // const handleSaveDownload = async () => {
  //   let exam = newExam()
  //   let bok = true
  //   if (isSuperAdmin) {
  //     if (escolas !== undefined && escolas.length > 0) {
  //       exam.CompanyId = escolas
  //     } else {
  //       bok = false
  //     }
  //   }
  //   if (bok) {
  //     setLoading(true)
  //     let newId
  //     if (editView) {
  //       const query = new URLSearchParams(window.location.search)
  //       newId = query.get('examId')
  //       await updateExam(newId, exam)
  //       setExamId(newId)
  //     } else {
  //       newId = await createExam(exam)
  //       setExamId(newId)
  //       await finishExam(newId, exam)
  //     }
  //     handleDownloadAction(newId)
  //     handleDownloadResolutionAction(newId)
  //     handleDownloadResolutionCompleteAction(newId)
  //     setLoading(false)
  //     history.push('/list-exams')
  //   } else {
  //     alert('Selecione escola(s)')
  //     return false
  //   }
  // }

  const handleSave = async (status) => {
    setLoading(true)
    let exam = newExam()
    let bok = true
    if (isSuperAdmin) {
      if (escolas !== undefined && escolas.length > 0) {
        exam.Companies = escolas
      } else {
        exam.Companies = []
      }
    }
    if (bok) {
      let newId
      if (editView) {
        const query = new URLSearchParams(window.location.search)
        newId = query.get('examId')
        exam.Status = status
        await updateExam(newId, exam)
        setExamId(newId)
      } else {
        newId = await createExam(exam)
        setExamId(newId)
        await finishExam(newId, exam)
      }
      setLoading(false)
      window.location.href = "/list-exams?save=True";
    } else {
      alert('Selecione escola(s)')
    }
  }

  const backButton = (
    <StyledButton
      htmlType='button'
      onClick={onCancel}
    >
      Voltar
    </StyledButton>
  )

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={backButton}
      css={QuestionsModalStyle}
      destroyOnClose
    >
      <Spin spinning={loading}>
        {/* {
          isSuperAdmin && <Row>
            <div className={'col-sm-12'}>
              <label>Escolas:</label>
              <SelectCompanies onChange={handleSelectCompany} companies={selected.CompanyId} />
            </div>
          </Row>
        } */}
        <Row>  
          <Buttons>
            <Grid container direction={isSmallScreen ? 'column' : 'row'} justifyContent={'center'}>
            {user && (
              user.Role === "Super" ||
              user.Role === "Master" ||
              user.Role === "District" ||
              (user.Role === "Teacher" && user.Coordinator === true) ||
              (user.Role === "Teacher" && user.SuperId === NH_SUPER_ID)) && 
              <StyledButton 
                type='default' 
                onClick={() => 
                handleSave('Active')} 
                shape='round'
                style={{width: '250px'}}
              >
                Salvar e aprovar
              </StyledButton>
            }
              <StyledButton
                type='default' 
                onClick={() => handleSave('Draft')} 
                shape='round'
                style={{width: '250px'}}
                >
                  Salvar como rascunho
              </StyledButton>
            </Grid>
          </Buttons>
          {/* <Buttons>
            <Button type='primary' onClick={() => handleSaveDownload()} shape='round'>Salvar e baixar</Button>
          </Buttons> */}
        </Row>
      </Spin>
    </Modal>
  )
}

UpdateModal.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.any,
  tags: PropTypes.array,
  title: PropTypes.string,
  editView: PropTypes.bool,
  downloadFile: PropTypes.string,
  questions: PropTypes.array,
  inactiveQuestions: PropTypes.array,
  realQuestions: PropTypes.array,
  examTags: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onShuffle: PropTypes.func,
  setExamId: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  user: PropTypes.object,
}

const mapStateToProps = ({ outputs, exams, users }) => ({
  hideWarnings: outputs.hideWarnings,
  loading: outputs.loading,
  downloadFile: exams.downloadFile,
  user: users.infoUser,
  id: exams.id
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchOutput,
    setExamId,
    finishExam,
    downloadExam
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UpdateModal)
