import React, { useState } from "react";

import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  TableCell,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateResultRW } from "../../../store/exams/actions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TextFieldDialog from "../../../components/modal/TextFieldDialog";
import { message } from "antd";

const AdaptiveAssessment = ({
  studentsResult,
  studentId,
  key,
  cellStyle,
  month,
  year,
  resultRW,
  setResultRW,
  updateResultRW,
}) => {
  const [openOthersAssessments, setOpenOthersAssessments] = useState(false);
  const [otherAssessment, setOtherAssessment] = useState({
    id: "",
    title: "",
  });

  const selectedAssessmentValue = (studentId) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    let assessment = "";
    let options = ["Não", "Alfabeto móvel", "Recurso Digital", "Outros"];

    assessment = studentData["Writing"]["AvaliacaoAdaptativa"];

    if (assessment === null) {
      assessment = "Não";
    }

    if (!options.includes(assessment)) {
      assessment = "Outros";
    }

    return assessment;
  };

  const handleAdaptiveAssessment = (e, studentId) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    const option = e.target.value;

    if (option === "Outros") {
      setOpenOthersAssessments(true);
    } else {
      studentData["Writing"]["AvaliacaoAdaptativa"] = option;
      setOtherAssessment({
        id: studentId,
        title: "",
      });
      updateResultRW(resultRW, studentData, year);
      message.info("Salvando marcação...");
    }
  };

  const showEditOtherAssessment = (studentId) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    let options = ["Não", "Alfabeto móvel", "Recurso Digital", "Outros"];

    if (studentData["Writing"]["AvaliacaoAdaptativa"]) {
      let title = studentData["Writing"]["AvaliacaoAdaptativa"];
      if (!options.includes(title)) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const handleEditOtherAssessment = (studentId) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    let title = studentData["Writing"]["AvaliacaoAdaptativa"];

    setOtherAssessment({
      id: studentId,
      title: title,
    });
    setOpenOthersAssessments(true);
  };

  const handleSaveOtherAssessment = () => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];

    if (otherAssessment.title !== "") {
      studentData["Writing"]["AvaliacaoAdaptativa"] = otherAssessment.title;
    } else {
      studentData["Writing"]["AvaliacaoAdaptativa"] = "Não";
    }
    resultRW = setResultRW(month, studentId, studentData);
    updateResultRW(resultRW, studentData, year);
    message.info("Salvando marcação...");
    closeOtherAssessmentDialog();
    setOtherAssessment({ title: "" });
  };

  const handleOtherAssessmentChange = (e) => {
    setOtherAssessment({
      id: studentId,
      title: e.target.value,
    });
  };

  const closeOtherAssessmentDialog = () => {
    setOpenOthersAssessments(false);
  };

  return (
    <>
      <TableCell
        key={key}
        component="th"
        scope="row"
        className={cellStyle}
        style={{ backgroundColor: "#EEE" }}
      >
        <Grid container direction="row" justifyContent="center">
          <Select
            value={selectedAssessmentValue(studentId)}
            onChange={(event) => handleAdaptiveAssessment(event, studentId)}
          >
            <MenuItem value="Não">Não</MenuItem>
            <MenuItem value="Alfabeto móvel">Alfabeto móvel</MenuItem>
            <MenuItem value="Recurso Digital">Recurso digital</MenuItem>
            <MenuItem value="Outros">Outros</MenuItem>
          </Select>
          {showEditOtherAssessment(studentId) && (
            <IconButton
              size="small"
              onClick={() => handleEditOtherAssessment(studentId)}
            >
              <EditOutlinedIcon />
            </IconButton>
          )}
        </Grid>
      </TableCell>

      <TextFieldDialog
        open={openOthersAssessments}
        closeDialog={closeOtherAssessmentDialog}
        textFieldValue={otherAssessment.title}
        handleChange={handleOtherAssessmentChange}
        handleSave={handleSaveOtherAssessment}
        title={"Insira o título da avaliação:"}
        label={"Título"}
      />
    </>
  );
};

AdaptiveAssessment.propTypes = {
  updateResultRW: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams }) => ({
  resultRW: exams.resultRW,
  updateRW: exams.updateRW,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateResultRW,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdaptiveAssessment);
