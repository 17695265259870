import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateResultRW } from "../../../store/exams/actions";
import { Grid, TextField, TableCell, Tooltip } from "@material-ui/core";
import DebounceInputField from "../../../components/input/DebounceInput";
import { message } from "antd";

const TimeInput = ({
  studentsResult,
  key,
  style,
  studentId,
  month,
  year,
  updateResultRW,
  setResultRW,
}) => {
  const getFieldValue = () => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];

    return studentData["Reading"]["Tempo"];
  };

  const handlePrecisionAndTime = (event) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    studentData["Reading"]["Tempo"] = event.target.value;
    const resultRW = setResultRW(month, studentId, studentData);
    updateResultRW(resultRW, studentData, year);
    message.info("Salvando marcação...");
  };

  return (
    <TableCell key={key} component="th" scope="row" className={style}>
      <Grid container direction="row" justifyContent="center">
        <Tooltip title="Quantos segundos?">
          <TextField
            value={getFieldValue()}
            type="number"
            InputProps={{
              inputComponent: DebounceInputField,
            }}
            onChange={handlePrecisionAndTime}
          />
        </Tooltip>
      </Grid>
    </TableCell>
  );
};

TimeInput.propTypes = {
  updateResultRW: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams }) => ({
  updateRW: exams.updateRW,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateResultRW,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TimeInput);
