export async function waitFor(
    readyFn = async () => true,
    retries = 10,
    delay = 1000,
) {
    for await (const _ of Array.from({ length: retries }, (_, i) => i)) {
        const isReady = await readyFn()
        if (isReady) return true
        await new Promise((resolve) => setTimeout(resolve, delay))
    }
    return false
}