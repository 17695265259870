// Environment
export const PRODUCTION = process.env.NODE_ENV === 'production'

// Files
export const SUPPORTED_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/msword',
  'application/rtf',
  'image/jpeg',
  'image/png'
]

// Auth
export const TOKEN_EXPIRATION_TIME = 2568604800000

// Compatibility
export const SUPPORTS_INDEXEDDB = !!(window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB)

// Templates
export const TEMPLATE_QUESTION_NUMBERING_OPTIONS = [
  { label: '1.', value: '#.\t' },
  { label: '01.', value: '##.\t' },
  { label: '1)', value: '#)\t' },
  { label: '01)', value: '##)\t' },
  { label: '(1)', value: '(#)\t' },
  { label: '(01)', value: '(##)\t' },
  { label: '1-', value: '#-\t' },
  { label: '1ª Questão ↵', value: '#ª Questão' },
  { label: 'QUEST 01.', value: 'QUEST ##.\t' },
  { label: 'QUEST 01 ↵', value: 'QUEST ##' },
  { label: 'QUESTÃO 1.', value: 'QUESTÃO #.\t' },
  { label: 'QUESTÃO 1 ↵', value: 'QUESTÃO #' },
  { label: 'QUESTÃO 01.', value: 'QUESTÃO ##.\t' },
  { label: 'QUESTÃO 01 ↵', value: 'QUESTÃO ##' },
  { label: 'Quest 01.', value: 'Quest ##.\t' },
  { label: 'Quest 01 ↵', value: 'Quest ##' },
  { label: 'Questão 1.', value: 'Questão #.\t' },
  { label: 'Questão 1 ↵', value: 'Questão #' },
  { label: 'Questão 01.', value: 'Questão ##.\t' },
  { label: 'Questão 01 ↵', value: 'Questão ##' }
]

export const TEMPLATE_ALTERNATIVE_OPTIONS = [
  { label: 'a.\t', value: 'a.\t' },
  { label: 'A.\t', value: 'A.\t' },
  { label: 'a)\t', value: 'a)\t' },
  { label: 'A)\t', value: 'A)\t' },
  { label: '(a)\t', value: '(a)\t' },
  { label: '(A)\t', value: '(A)\t' }
]

export const TEMPLATE_ALTERNATIVE_ALIGNMENT = [
  { label: '1. blablabla\nblablablabla\n    a) 1\n    b) 2', value: 1 },
  { label: '1. blablabla\n     blablablabla\n    a) 1\n    b) 2', value: 2 },
  { label: '1. blablabla\nblablablabla\na) 1\nb) 2', value: 3 }
]

export const TEMPLATE_FONT_FAMILIES = [
  { label: 'Arial', value: 'Arial' },
  { label: 'Calibri', value: 'Calibri' },
  { label: 'Cambria', value: 'Cambria' },
  { label: 'Courier New', value: 'Courier New' },
  { label: 'Dextor D', value: 'Dextor D' },
  { label: 'Dextor Out D', value: 'Dextor Out D' },
  { label: 'Nila', value: 'Nila' },
  { label: 'Nirmala', value: 'Nirmala' },
  { label: 'Nueva', value: 'Nueva' },
  { label: 'Sim Sun', value: 'Sim Sun' },
  { label: 'The Northern Block', value: 'The Northern Block' },
  { label: 'Times New Roman', value: 'Times New Roman' }
]

export const TEMPLATE_FONT_SIZES = [
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 }
]

export const COLUMN_OPTIONS = [
  { label: 'Uma Coluna', value: 'Uma Coluna' },
  { label: 'Duas Colunas', value: 'Duas Colunas' }
]

export const TEMPLATE_RATIOS = {
  'Arial': {
    '##.\t': {
      10: 0.85 / 0.58,
      11: 0.90 / 0.65,
      12: 0.95 / 0.70,
      13: 1.05 / 0.77,
      14: 1.10 / 0.82
    },
    '##)\t': {
      10: 0.85 / 0.61,
      11: 0.90 / 0.67,
      12: 0.95 / 0.73,
      13: 1.05 / 0.79,
      14: 1.10 / 0.85
    },
    '(##)\t': {
      10: 0.95 / 0.73,
      11: 1.10 / 0.79,
      12: 1.20 / 0.87,
      13: 1.30 / 0.94,
      14: 1.40 / 1.02
    }
  },
  'Calibri': {
    '##.\t': {
      10: 0.75 / 0.53,
      11: 0.80 / 0.59,
      12: 0.87 / 0.64,
      13: 0.94 / 0.69,
      14: 1.01 / 0.73
    },
    '##)\t': {
      10: 0.75 / 0.54,
      11: 0.80 / 0.60,
      12: 0.88 / 0.66,
      13: 0.96 / 0.72,
      14: 1.04 / 0.76
    },
    '(##)\t': {
      10: 0.90 / 0.66,
      11: 0.98 / 0.72,
      12: 1.07 / 0.78,
      13: 1.14 / 0.84,
      14: 1.25 / 0.90
    }
  },
  'Times New Roman': {
    '##.\t': {
      10: 0.78 / 0.52,
      11: 0.85 / 0.59,
      12: 0.92 / 0.64,
      13: 0.99 / 0.69,
      14: 1.06 / 0.74
    },
    '##)\t': {
      10: 0.80 / 0.57,
      11: 0.87 / 0.60,
      12: 0.94 / 0.67,
      13: 1.01 / 0.74,
      14: 1.08 / 0.78
    },
    '(##)\t': {
      10: 0.94 / 0.67,
      11: 1.02 / 0.74,
      12: 1.12 / 0.83,
      13: 1.22 / 0.89,
      14: 1.32 / 0.94
    }
  },
  'Cambria': {
    '##.\t': {
      10: 0.78 / 0.54,
      11: 0.85 / 0.59,
      12: 0.92 / 0.64,
      13: 0.99 / 0.70,
      14: 1.06 / 0.75
    },
    '##)\t': {
      10: 0.82 / 0.60,
      11: 0.89 / 0.67,
      12: 0.96 / 0.74,
      13: 1.05 / 0.78,
      14: 1.15 / 0.84
    },
    '(##)\t': {
      10: 1.01 / 0.74,
      11: 1.09 / 0.82,
      12: 1.20 / 0.89,
      13: 1.30 / 0.96,
      14: 1.40 / 1.04
    }
  }
}

export const NH_SUPER_ID = "4ccf0619-370e-4288-bb61-15a9f3427b6f";
export const FORTALEZA_SUPER_ID = "e21bbd27-da35-4424-af29-b9afa880d204";
export const CNEC_SUPER_ID = "9ff193cd-2501-4de0-a273-8dc7272a4b81";

export const STUDENT_FORM_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSfEka-oo_EqM81huVbhAYOMoaeHFe5c8jKWd8u4rNfgpP18PA/viewform?embedded=true";
export const INTERN_FORM_LINK = "https://docs.google.com/forms/d/e/1FAIpQLScIoXT86vVP64cvQUxSGwCZZ_S0D4s25-MeT8BndzICFex57A/viewform?embedded=true";
export const EMPLOYEE_FORM_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSd53KTS1mp7bpwZpidPfjRfCsGgXSY4rAibDbkqiP1oQF9gmQ/viewform?embedded=true";
export const TEACHER_FORM_LINK = "https://docs.google.com/forms/d/e/1FAIpQLScFLgQyHkrpBJL_Af7tK7z9S12EIibQY024n474GrU2xwdqLA/viewform?embedded=true";
export const MASTER_FORM_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSeNS9XwR2CVH3MCUpt9uy8U6BKEN8KUgN5j0ahvQTnN1Bwp_g/viewform?embedded=true";
export const LIST_OF_SUPER_IDS_WITH_TRI = ["e21bbd27-da35-4424-af29-b9afa880d204"];
export const FORM_LINK = "https://docs.google.com/forms/d/e/1FAIpQLScBGE3kMo2c6DU0Jp5Ky6CGLCvYUbOFtftqpPwmhvBIozXQLg/viewform?embedded=true";