import React, { useEffect, useState } from "react";
import { Page } from "./styles/Page.styles";
import { Content } from "./styles/DetailsPage.styles";

import { Header } from "./styles/ListExamsPage.styles";
import Releases from "../components/releases/Releases";

function ReleasePage() {
  const [area, setArea] = useState("");

  const areaLabels = {
    Reading: "Fluência de Leitura",
    Writing: "Nível de Escrita",
    Survey: "Formulário",
  };
  const areaLabel = areaLabels[area];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedOption = urlParams.get("selected");
    setArea(selectedOption);
  }, []);

  return (
    <Page style={{ height: "100vh" }}>
      <Content style={{ width: "100%", maxWidth: "1200px", marginTop: "40px" }}>
        {area !== "Survey" && <Header>{areaLabel}</Header>}
        <Releases area={area} />
      </Content>
    </Page>
  );
}

export default ReleasePage;
