import React, { useState, useEffect } from "react";
import TableCellMemo from "../../table/TableCellMemo";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateResultRW } from "../../../store/exams/actions";
import { message } from "antd";

const AssessmentCheckbox = ({
  semanticField,
  initialStudentsData,
  conditionalFields,
  style,
  key,
  studentId,
  month,
  year,
  field,
  area,
  checked,
  updateResultRW,
  resultRW,
  updateRW,
  setObservation,
  setOpenObservation,
  showEditObservationButton,
  handleEditObservation,
}) => {

  const checkedFieldColors = {
    Presilabico: "#F13636",
    SilabicoSemValorSonoro: "#FF5C28",
    SilabicoComValorSonoro: "#F2DA00",
    SilabicoAlfabetico: "#5579F6",
    Alfabetico: "#32BD41",
    NaoAvaliado: "#302929",
    NaoAvaliadoRF: "#302929",
    NaoLeitor: "#B7B7B7",
    Silaba: "#F13636",
    Palavra: "#FF5C28",
    Frase: "#F2DA00",
    TextoSemFluencia: "#5570F6",
    TextoComFluencia: "#32BD41",
  };

  const setResultRW = (month, studentId, modifiedData) => {
    let newRw = resultRW;
    newRw[month][studentId]["SemanticField"] = modifiedData["SemanticField"];
    newRw[month][studentId]["Observation"] = modifiedData["Observation"];
    newRw[month][studentId]["Reading"] = modifiedData["Reading"];
    newRw[month][studentId]["Writing"] = modifiedData["Writing"];

    return newRw;
  };

  const getStudenstResult = () => {
    const students = Object.entries(resultRW[month])
      .map(([key, value]) => ({
        StudentId: key,
        ...value,
      }))
      .sort((a, b) => a.Name.localeCompare(b.Name));
    return students;
  };

  const handleCheckbox = (area, month, studentId, field, semanticField, key) => {
    const studentsResult = getStudenstResult();
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let data = studentsResult[studentIndex];
    data = {
      ...data,
      SemanticField: area === "Writing" && semanticField ? semanticField : data.SemanticField,
      [area]: Object.keys(data[area]).reduce((acc, key) => {
        if (!conditionalFields.includes(key)) {
          acc[key] = key === field ? !data[area][field] : false;
        } else {
          acc[key] = data[area][key];
        }
        return acc;
      }, {}),
    };

    if (
      (field === "NaoAvaliado" || field === "NaoAvaliadoRF") &&
      data[area][field]
    ) {
      setObservation({
        id: studentId,
        message: "",
      });
      setOpenObservation(true);
    } else {
      if (data["Observation"]) {
        data["Observation"][area] = "";
      }
    }

    const resultRW = setResultRW(month, studentId, data);
    updateResultRW(resultRW, data, year);
    message.info("Salvando marcação...");
  };

  return (
    <>
      <TableCellMemo
        key={key}
        studentId={studentId}
        semanticField={semanticField}
        month={month}
        field={field}
        area={area}
        checked={checked}
        checkedFieldColors={checkedFieldColors}
        handleCheckbox={handleCheckbox}
        paddingCell={style}
        showEditButton={showEditObservationButton(studentId, field)}
        handleEditObservation={() => handleEditObservation(studentId)}
      />
    </>
  );
};

AssessmentCheckbox.propTypes = {
  updateResultRW: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams }) => ({
  resultRW: exams.resultRW,
  updateRW: exams.updateRW,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateResultRW,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentCheckbox);
