import { all, call, select, takeLatest, put } from "redux-saga/effects";
import { normalizeError } from "../../utils/errorHelpers";
import { message } from "antd";
import history from "../../routes/history";

import * as actions from "./actions";
import * as types from "./constants";
import * as services from "./services";

const filterGabarito = (gabaritoStr) => {
  let matches;
  let alternatives = [];
  gabaritoStr = gabaritoStr.toUpperCase();
  while ((matches = /(\d*)[^0-9]*([A-EXa-ex])/g.exec(gabaritoStr)) !== null) {
    let index = parseInt(matches[1]) > 0 ? parseInt(matches[1]) - 1 : -1;
    let alt = matches[2].toUpperCase();
    alternatives[index] = index >= 0 ? alt : undefined;
    gabaritoStr = gabaritoStr.replace(matches[0], "");
  }

  return alternatives;
};

const handleAnswers = (gabaritoStr) => {
  let alternatives = [];

  if (/\d/.test(gabaritoStr)) {
    alternatives = filterGabarito(gabaritoStr);
  } else {
    let matches = gabaritoStr.match(/([A-EX*a-ex])/g);
    alternatives =
      matches !== null ? matches.map((letter) => letter.toUpperCase()) : [];
  }

  return alternatives;
};

export function* uploadRecognition({ payload: files }) {
  try {
    let ids = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        const rec = yield call(services.uploadRecognition, files[i]);

        let id = {
          id: rec ? rec.ExamId : undefined,
          name: files[i].name,
        };
        ids = [...ids, id];
      }
    }

    yield put(actions.uploadRecognitionSuccess(ids));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar documento");
    message.error(errorInfo.message);
    yield put(actions.uploadRecognitionFailure(errorInfo));
  }
}

export function* uploadExam({ payload: { files, authorization, scholarship } }) {
  try {
    let ids = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        let id;
        if (authorization) {
          id = yield call(services.uploadExamV2, files[i], scholarship);
        } else {
          id = yield call(services.uploadExam, files[i]);
        }
        ids = [...ids, id];
      }
    }

    if (authorization && scholarship) {
      if (ids.length !== 0 && ids !== null && ids !== undefined) {
        let url = "/list-exams/list-questions?";
        for (let i = 0; i < ids.length; i++) {
          url += "examId=" + ids[i] + "&";
        }
        console.log(url);
        window.location.href = url;
        // history.push(url)
      }
    }

    history.push("/classification?examId=" + ids[0] + "&edit=false&scholarship=" + scholarship);
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar documento");
    message.error(errorInfo.message);
    yield put(actions.uploadExamFailure(errorInfo));
  }
}

export function* uploadExamMobile({ payload: files }) {
  try {
    let ids = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        let id = yield call(services.uploadExamMobile, files[i]);
        ids = [...ids, id];
      }
    }

    if (ids.length !== 0 && ids !== null && ids !== undefined) {
      let url = "/list-exams/list-questions?";
      for (let i = 0; i < ids.length; i++) {
        url += "examId=" + ids[i] + "&";
      }
      console.log(url);
      window.location.href = url;
      // history.push(url)
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar documento");
    message.error(errorInfo.message);
    yield put(actions.uploadExamMobileFailure(errorInfo));
  }
}

export function* fetchClassifications({ payload: { id, scholarship } }) {
  try {
    let pages = [yield call(services.fetchClassifications, id)];
    if (pages && pages.length === 1 && pages[0] === undefined) {
      throw new Error();
    }

    yield put(actions.uploadExamSuccess(pages));

    let url = "/classification?examId=" + id + "&edit=true";
    if (scholarship) {
      url += "&scholarship=" + scholarship;
    }

    history.push(url);
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Essa prova foi montada e, por isso, não pode ser classificada."
    );
    message.error(errorInfo.message);
    yield put(actions.uploadExamFailure(errorInfo));
  }
}

export function* fetchRecognized({ payload: id }) {
  try {
    let pages = [yield call(services.fetchClassifications, id)];
    if (pages && pages.length === 1 && pages[0] === undefined) {
      throw new Error();
    }
    yield put(actions.uploadExamSuccess(pages));
  } catch (error) {
    // const errorInfo = normalizeError(error, 'Essa prova foi montada e, por isso, não pode ser classificada.')
    // message.error(errorInfo.message)
    // yield put(actions.uploadExamFailure(errorInfo))
    console.log(error);
  }
}

export function* fetchChangePage({ payload: action }) {
  if (action === true) {
    yield put(actions.changePageSuccess());
  } else {
    yield put(actions.changePageFailure());
  }
}

export function* fetchExamReport({ payload: { examId, newReport } }) {
  try {
    let response = yield call(services.fetchExamReport, examId, newReport);
    console.log(response);
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao gerar relatório da prova"
    );
    message.error(errorInfo.message);
  }
}

export function* sendImages({ payload: image }) {
  try {
    let urls = [];
    for (let i = 0; i < image.length; i++) {
      if (image[i]) {
        let url = yield call(services.sendImages, image[i].file);
        urls = [...urls, url];
      }
    }

    console.log(urls);
    try {
      yield put(actions.sendImagesSuccess(urls));
    } catch (e) {
      console.error(e.message);
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar documento");
    message.error(errorInfo.message);
    yield put(actions.uploadExamFailure(errorInfo));
  }
}

export function* classifyExam({ payload: classification }) {
  try {
    let ids = [];
    let url = new URL(window.location.href);
    let scholarship = url.searchParams.get("scholarship");
    console.log(classification, scholarship);

    for (let i = 0; i < classification.length; i++) {
      if (classification[i]) {
        let id = yield call(services.classifyExam, {
          ...classification[i],
          Scholarship: scholarship
        });
        ids = [...ids, id.ExamId];
      }
    }

    try {
      if (ids.length > 0) {
        window.location.href = "/list-exams/list-questions?examId=" + ids[0];
      }
    } catch (e) {
      console.error(e.message);
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar documento");
    message.error(errorInfo.message);
    yield put(actions.classifyExamFailure(errorInfo));
  }
}

export function* fetchAnoTrilhas() {
  try {
    var response = yield call(services.fetchAnoTrilhas);
    yield put(actions.fetchAnoTrilhasSuccess(response.anos));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchAnoTrilhasFailure(errorInfo));
  }
}

export function* fetchDisciplinasTrilhas({ payload: { ano } }) {
  try {
    var response = yield call(services.fetchDisciplinasTrilhas, ano);
    console.log(response)
    yield put(actions.fetchDisciplinasTrilhasSuccess(response.disciplinas));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchDisciplinasTrilhasFailure(errorInfo));
  }
}

// export function* fetchStudentsTrilhas({ payload: { ano, disciplina } }) {
//   try {
//     var trilhas = yield call(services.fetchStudentsTrilhas, ano, disciplina);
//     yield put(actions.fetchStudentsTrilhasSuccess(disciplina));
//   } catch (error) {
//     const errorInfo = normalizeError(error.response.data, error.response.data);
//     yield put(actions.fetchStudentsTrilhasFailure(errorInfo));
//   }
// }

export function* fetchReadAndWriting({ payload: { grade, companyId, year } }) {
  try {
    var results = yield call(services.fetchReadingWriting, grade, companyId, year);
    if(results !== undefined){
      yield put(actions.fetchReadAndWritingSuccess(results));
    } else {
      const errorInfo = normalizeError("", "Falha ao obter resultados");
      yield put(actions.fetchReadAndWritingFailure(errorInfo));
      message.error(errorInfo.message);
    }
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchReadAndWritingFailure(errorInfo));
  }
}

export function* fetchReadAndWritingCSVData() {
  try {
    var results = yield call(services.fetchReadingAndWritingCSVData);
    if(results !== undefined){
      yield put(actions.fetchReadingAndWritingCSVDataSuccess(results));
    } else {
      const errorInfo = normalizeError("", "Falha ao obter resultados");
      yield put(actions.fetchReadingAndWritingCSVDataFailure(errorInfo));
      message.error(errorInfo.message);
    }
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchReadingAndWritingCSVDataFailure(errorInfo));
  }
}

export function* updateResultRW({ payload: { resultRW, modifiedData, year } }) {
  try {
    const requestData = { ...modifiedData, year };
    yield call(services.setReadingWriting, requestData);
    yield put(actions.updateResultRWSuccess(resultRW));
  } catch (error) {
    const errorInfo = normalizeError(error, "Erro interno ou de conexão, tente novamente mais tarde");
    message.error("Erro ao salvar, verifique a conexão.");
    yield put(actions.updateResultRWFailure(errorInfo));
  }
}

export function* fetchConsolidacao({ payload: { serie } }) {
  try {
    var response = yield call(services.fetchConsolidacao, serie);
    yield put(actions.fetchConsolidacaoSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.updateResultRWFailure(errorInfo));
  }
}

export function* fetchResultTrilhas({ payload: { ano, disciplina } }) {
  try {
    var results = yield call(services.fetchResultTrilhas, ano, disciplina);
    yield put(actions.fetchResultTrilhasSuccess(results));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchResultTrilhasFailure(errorInfo));
  }
}

export function* fetchUnidadesTematicas({ payload: { ano, disciplina } }) {
  try {
    var results = yield call(services.fetchUnidadesTematicas, ano, disciplina);
    yield put(actions.fetchUnidadesTematicasSuccess(results));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchUnidadesTematicasFailure(errorInfo));
  }
}

export function* fetchOnlineExamInfo({ payload: { examId } }) {
  try {
    var exam = yield call(services.fetchOnlineExamInfo, examId);
    yield put(actions.fetchOnlineExamInfoSuccess(exam));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchOnlineExamInfoFailure(errorInfo));
  }
}

export function* checkPagesAvailable() {
  try {
    let numPagesAvailable = yield call(services.checkPagesAvailable);
    if (numPagesAvailable !== undefined) {
      yield put(actions.checkPagesSuccess(numPagesAvailable["Pages"]));
    } else {
      const errorInfo = normalizeError("", "Número de páginas não recebido");
      message.error(errorInfo.message);
      yield put(actions.checkPagesFailure(errorInfo));
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao comunicar com servidor");
    message.error(errorInfo.message);
    yield put(actions.checkPagesFailure(errorInfo));
  }
}

export function* fetchOnlineExam({ payload: { examId, studentId, isOpen } }) {
  try {
    var resolutions = yield call(
      services.fetchOnlineExam,
      examId,
      studentId,
      isOpen
    );
    console.log(resolutions);
    let fixAns;
    if (resolutions && resolutions.Questions) {
      fixAns = resolutions.Questions.map((obj) => {
        for (let i = 0; i < obj.Alternatives.length; i++) {
          obj.Alternatives[i].Correct = false;
        }
        return obj;
      });
    }
    resolutions.Questions = fixAns;
    console.log("res", resolutions.Questions);
    yield put(actions.fetchOnlineExamSuccess(resolutions));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    message.error(
      errorInfo.message.Message ? errorInfo.message.Message : errorInfo.message
    );
    yield put(actions.fetchOnlineExamFailure(errorInfo));
  }
}

export function* fetchPowerBIReport({ payload: { examIds, newReport } }) {
  try {
    let response = yield call(services.fetchPowerBIReport, examIds, newReport);
    if (
      response !== undefined &&
      response.ReportId &&
      response.EmbedUrl &&
      response.EmbedToken
    ) {
      yield put(actions.fetchPowerBIReportSuccess(response));
    } else {
      const errorInfo = normalizeError("", "Falha ao gerar powerBI Report");
      message.error(errorInfo.message);
      yield put(actions.fetchPowerBIReportFailure(errorInfo));
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao gerar powerBI Report");
    message.error(errorInfo.message);
    yield put(actions.fetchPowerBIReportFailure(errorInfo));
  }
}

// Ele carrega o nome e Id das provas
export function* fetchResultExam({
  payload: { title, page, dateFrom, dateTotags, itemsPerPage },
}) {
  try {
    let exams = {
      exams: [],
      total: 0,
    };
    console.log(itemsPerPage);
    let response = yield call(
      services.fetchResultExam,
      title,
      dateFrom,
      dateTotags,
      page,
      itemsPerPage
    );
    console.log(response);
    exams.total = response.TotalItens;
    exams.exams = response.Itens;
    yield put(actions.fetchExamsSuccess(exams));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao processar lista de exames"
    );
    message.error(errorInfo.message);
    yield put(actions.fetchExamsFailure(errorInfo));
  }
}

export function* fetchIndividualResultExam({
  payload: { title, page, dateFrom, dateTotags, itemsPerPage },
}) {
  try {
    let exams = {
      exams: [],
      total: 0,
    };
    console.log(itemsPerPage);
    let response = yield call(
      services.fetchIndividualResultExam,
      title,
      dateFrom,
      dateTotags,
      page,
      itemsPerPage
    );
    console.log(response);
    exams.total = response.TotalItens;
    exams.exams = response.Itens.map((i) => Object.assign({}, i.i, i.it));
    yield put(actions.fetchExamsSuccess(exams));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao processar lista de exames"
    );
    message.error(errorInfo.message);
    yield put(actions.fetchExamsFailure(errorInfo));
  }
}

export function* fetchStudentExams({
  payload: { title, page, dateFrom, dateTotags, itemsPerPage },
}) {
  try {
    let exams = {
      exams: [],
      total: 0,
    };
    console.log(itemsPerPage);
    let response = yield call(
      services.fetchStudentExams,
      title,
      dateFrom,
      dateTotags,
      page,
      itemsPerPage
    );
    console.log(response);
    exams.total = response.TotalItens;
    exams.exams = response.Itens;
    yield put(actions.fetchExamsSuccess(exams));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao processar lista de exames"
    );
    message.error(errorInfo.message);
    yield put(actions.fetchExamsFailure(errorInfo));
  }
}

export function* fetchStudentResult({ payload: { examId, userId, isOpen } }) {
  try {
    let response = yield call(
      services.fetchStudentResult,
      examId,
      userId,
      isOpen
    );
    console.log(response);
    yield put(actions.fetchStudentResultSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao processar lista de exames"
    );
    message.error(errorInfo.message);
    yield put(actions.fetchStudentResultExamFailure(errorInfo));
  }
}

export function* fetchStudentResultAll({ payload: { userId } }) {
  try {
    let response = yield call(
      services.fetchStudentResultAll,
      userId,
    );
    console.log(response);
    yield put(actions.fetchStudentResultAllSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao processar lista de exames"
    );
    message.error(errorInfo.message);
    yield put(actions.fetchStudentResultAllFailure(errorInfo));
  }
}

export function* fetchStudentResultExam({
  payload: { examId, page, itemsPerPage, newReport, districts },
}) {
  try {
    let results = {
      itens: [],
      total: 0,
    };
    let response = yield call(
      services.fetchStudentResultExam,
      { examId, page, itemsPerPage, newReport, districts}
    );

    // const fakeObj = {
    //   'escola1': {
    //     'turma1': {
    //       'id1': {
    //         'questions': [1, 1, 1, 1],
    //         'tags_mean': {
    //           "tag1": 0.1,
    //           "tag2": 0.8
    //         },
    //         'name': 'zezim1'
    //       },
    //       'id2': {
    //         'questions': [1, 1, 1, 0],
    //         'tags_mean': {
    //           "tag1": 0.2,
    //           "tag2": 0.3
    //         },
    //         'name': 'zezim2'
    //       },
    //     },
    //     'turma2': {
    //       'id3': {
    //         'questions': [0, 1, 1, 0],
    //         'tags_mean': {
    //           "tag1": 0.5,
    //           "tag2": 0.9
    //         },
    //         'name': 'zezim3'
    //       },
    //       'id4': {
    //         'questions': [0, 0, 1, 1],
    //         'tags_mean': {
    //           "tag1": 0.4,
    //           "tag2": 0.6
    //         },
    //         'name': 'zezim4'
    //       },
    //     }

    //   }
    // };

    // action de Success tem um reducer mapeado a ela
    // o parâmetro que passamos pro Success é lido com action.payload pelo reducer
    yield put(actions.fetchStudentResultExamSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao processar lista de exames"
    );
    message.error(errorInfo.message);
    yield put(actions.fetchStudentResultExamFailure(errorInfo));
  }
}

export function* openTestUpdate({
  payload: { examId, openId, openTestUserMean },
}) {
  try {
    let response = yield call(
      services.openTestUpdate,
      examId,
      openId,
      openTestUserMean
    );
    console.log(response);
    yield put(actions.openTestUpdateSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao atualizar Exame Aberto");
    message.error(errorInfo.message);
    yield put(actions.openTestUpdateFailure(errorInfo));
  }
}

export function* fetchStudentIndividualResultExam({
  payload: { examId, studentId },
}) {
  try {
    let response = yield call(
      services.fetchStudentIndividualResultExam,
      examId,
      studentId
    );
    console.log(response);
    yield put(actions.fetchStudentIndividualResultExamSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    message.error(
      errorInfo.message.Message ? errorInfo.message.Message : errorInfo.message
    );
    yield put(actions.fetchStudentIndividualResultExamFailure(errorInfo));
  }
}

export function* startOnlineExam({ payload: { examId, studentId, isOpen } }) {
  try {
    let userId = yield call(
      services.startOnlineExam,
      examId,
      studentId,
      isOpen
    );
    console.log(userId);
    if (userId === undefined) {
      throw new Error("Falha ao iniciar exame");
    } else {
      yield put(actions.startOnlineExamSuccess(userId));
    }
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    message.error(
      errorInfo.message.Message ? errorInfo.message.Message : errorInfo.message
    );
    yield put(actions.startOnlineExamFailure(errorInfo));
  }
}

export function* endOnlineExam({ payload: { examId, studentId, isOpen } }) {
  try {
    let response = yield call(
      services.endOnlineExam,
      examId,
      studentId,
      isOpen
    );
    if (response === undefined) {
      throw new Error("Falha ao terminar exame");
    } else {
      yield put(actions.endOnlineExamSuccess());
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao terminar exame");
    message.error(errorInfo.message);
    yield put(actions.endOnlineExamFailure(errorInfo));
  }
}

export function* ansQuestion({ payload: { ans, isOpen } }) {
  try {
    yield call(services.ansQuestion, ans, isOpen);
    yield put(actions.ansQuestionSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao responder questão");
    message.error(errorInfo.message);
    yield put(actions.ansQuestionFailure(errorInfo));
  }
}

export function* fetchExam({ payload: id }) {
  try {
    var resolutions = yield call(services.fetchExam, id);
    yield put(actions.fetchExamSuccess(resolutions));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar exame");
    message.error(errorInfo.message);
    yield put(actions.fetchExamFailure(errorInfo));
  }
}

export function* fetchExamResult({ payload: id }) {
  try {
    var resolutions = yield call(services.fetchExamResult, id);
    console.log("res", resolutions);
    yield put(actions.fetchExamResultSuccess(resolutions));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao processar resultado do exame"
    );
    message.error(errorInfo.message);
    yield put(actions.fetchExamResultFailure(errorInfo));
  }
}

export function* fetchExamImage({ payload: id }) {
  try {
    var resolutions = yield call(services.fetchExamImage(), id);
    yield put(actions.fetchExamImageSuccess(resolutions));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao buscar imagem do exame");
    message.error(errorInfo.message);
    yield put(actions.fetchExamImageFailure(errorInfo));
  }
}

export function* fetchEquationImage({ payload: id }) {
  try {
    var resolutions = yield call(services.fetchEquationImage, id);
    yield put(actions.fetchEquationImageSuccess(resolutions));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao busca imagem da equação");
    message.error(errorInfo.message);
    yield put(actions.fetchEquationImageFailure(errorInfo));
  }
}

export function* downloadExam({ payload: data }) {
  try {
    let resolutions = yield call(services.downloadExam, data);
    yield put(actions.downloadExamSuccess(resolutions));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao fazer download do exame");
    message.error(errorInfo.message);
    yield put(actions.downloadExamFailure(errorInfo));
  }
}

export function* finishExam({ payload: resolutions }) {
  try {
    let examID = yield select((state) => state.exams.id);

    let id = yield call(services.finishExam, examID, resolutions);
    yield put(actions.finishExamSuccess(id));

    history.push("/list-exams");
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar exame");
    message.error(errorInfo.message);
    yield put(actions.finishExamFailure(errorInfo));
  }
}

export function* fetchExams({
  payload: { title, page, tags, itemsPerPage, userId, companyId, isSimulie, examType, queryTree, examStatus },
}) {
  try {
    let exams = {
      exams: [],
      total: 0,
    };
    console.log("sagas", userId, companyId, isSimulie, queryTree);
    let response = yield call(
      services.fetchExams,
      title,
      page,
      tags,
      itemsPerPage,
      userId,
      companyId,
      isSimulie,
      examType,
      queryTree,
      examStatus,
    );
    exams.total = response.TotalItens;
    exams.exams = response.Itens;
    yield put(actions.fetchExamsSuccess(exams));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao processar lista de exames"
    );
    message.error(errorInfo.message);
    yield put(actions.fetchExamsFailure(errorInfo));
  }
}

export function* fetchExamMatrixEF({ payload: { companyId } }) {
  try {
    var results = yield call(services.fetchExamMatrixEF, companyId, "EF");
    yield put(actions.fetchExamMatrixEFSuccess(results[0]));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchExamMatrixEFFailure(errorInfo));
  }
}

export function* fetchExamMatrixEM({ payload: { companyId } }) {
  try {
    var results = yield call(services.fetchExamMatrixEM, companyId, "EM");
    yield put(actions.fetchExamMatrixEMSuccess(results[0]));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchExamMatrixEMFailure(errorInfo));
  }
}

export function* fetchExamHabilities({ payload: { companyId } }) {
  try {
    var results = yield call(services.fetchExamHabilities, companyId);
    yield put(actions.fetchExamHabilitiesSuccess(results[0]));
  } catch (error) {
    const errorInfo = normalizeError(error.response.data, error.response.data);
    yield put(actions.fetchExamHabilitiesFailure(errorInfo));
  }
}

export function* createQuestionsByAI({ payload }) {
  try {
    for (let i = 0; i < payload.quantity; i++) {
      const loading = i !== payload.quantity - 1;
      let res = yield call(services.createQuestionsByAI, payload);
      yield put(actions.createQuestionsByAISuccess(res, loading));
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao criar questões por IA");
    message.error(errorInfo.message);
    yield put(actions.createQuestionsByAIFailure(errorInfo));
  }
}

export function* deleteExam({ payload: id }) {
  try {
    yield call(services.deleteExam, id);
    yield put(actions.deleteExamSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao deletar exame");
    message.error(errorInfo.message);
    yield put(actions.deleteExamFailure(errorInfo));
  }
}

export function* deleteTagExam({
  payload: { examId, tag, title, page, tags, itemsPerPage, userId, companyId },
}) {
  try {
    yield call(services.deleteTagExam, examId, tag);
    let response = fetchExams(
      title,
      page,
      tags,
      itemsPerPage,
      userId,
      companyId
    );
    yield put(actions.fetchExamsSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao deletar tag");
    message.error(errorInfo.message);
    yield put(actions.deleteTagExamFailure(errorInfo));
  }
}

export function* addTagExam({ payload: { examId, tag } }) {
  try {
    var response = yield call(services.addTagExam, examId, tag);
    yield put(actions.addTagSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao deletar tag");
    message.error(errorInfo.message);
    yield put(actions.addTagFailure(errorInfo));
  }
}

export function* fetchSelected({ payload: { examsId, selected } }) {
  try {
    var exams = yield call(services.fetchSelected, examsId);
    yield put(actions.fetchSelectedSuccess(exams));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao processar provas selecionadas"
    );
    message.error(errorInfo.message);
    yield put(actions.fetchSelectedFailure(errorInfo));
  }
}

export function* deleteQuestion({ payload: { examId, questionId } }) {
  try {
    yield call(services.deleteQuestion, examId, questionId);

    const selected = yield select((state) => state.exams.selected);

    const updateSelected = selected.map((exam) => {
      var tempExam = Object.assign({}, exam);

      if (tempExam.Id === examId) {
        tempExam.Questions = tempExam.Questions.filter(
          (question) => question.Id !== questionId
        );
      }
      return tempExam;
    });

    yield put(actions.deleteQuestionSuccess(updateSelected));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao deletar questão");
    message.error(errorInfo.message);
    yield put(actions.deleteTagExamFailure(errorInfo));
  }
}

export function* searchExamTag({ payload: { tags, page } }) {
  try {
    var exams = yield call(services.searchExamTag, tags, page);

    yield put(actions.searchExamTagSuccess(exams));
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao buscar lista de provas por tag"
    );
    message.error(errorInfo.message);
    yield put(actions.searchExamTagFailure(errorInfo));
  }
}

export function* createExam({ payload: { exam } }) {
  try {
    let examId = yield call(services.createExam, exam);
    console.log(examId);
    exam.Id = examId;
    window.sessionStorage.setItem("download", examId);
    yield put(actions.examDownloadSuccess(examId));
    yield call(services.finishExam, examId, exam);
    yield put(actions.createExamSuccess(examId));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar documento");
    message.error(errorInfo.message);
    yield put(actions.createExamFailure(errorInfo));
  }
}

export function* getVideosLoaded({ payload: examId }) {
  try {
    let res = yield call(services.getVideosLoaded, examId);
    if (res && res.IsLoaded) {
      yield put(actions.getVideosLoadedSuccess());
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao verificar vídeos");
    message.error(errorInfo.message);
    yield put(actions.getVideosLoadedFailure(errorInfo));
  }
}

export function createAndDownloadBlobFile(body, filename, extension = "pdf") {
  const blob = new Blob([body]);
  const fileName = `${filename}.${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    console.log("blob", url);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function* updateExam({ payload: { exam, download } }) {
  try {
    var examId = yield call(services.updateExam, exam);

    if (download) {
      const host = process.env.APP_SERVER_URL;
      const fileName = examId;

      const link = document.createElement("a");
      link.href = `${host}/exam/download/${examId}`;
      link.download = fileName;
      document.body.append(link);
      link.click();
      link.remove();
    }

    yield put(actions.updateExamSuccess(examId));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar documento");
    message.error(errorInfo.message);
    yield put(actions.updateExamFailure(errorInfo));
  }
}

export function* searchSelected({
  payload: {
    educationType,
    tipoProva,
    tipoQuestao,
    provaIds,
    questaoTags,
    year,
    listOfMatrixObject,
    listOfMatrixHabilityObject,
    treeId,
    treeIdHabilities,
    page,
    limit,
    byBatch = false,
    searchStatement,
  },
}) {
  try {
    const selected = yield call(
      services.searchSelected,
      educationType,
      tipoProva,
      tipoQuestao,
      provaIds,
      questaoTags,
      year,
      listOfMatrixObject,
      listOfMatrixHabilityObject,
      treeId,
      treeIdHabilities,
      page,
      limit,
      searchStatement
    );
    yield put(actions.searchSelectedSuccess({ byBatch, data: selected }));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao processar documento");
    message.error(errorInfo.message);
    yield put(actions.searchSelectedFailure(errorInfo));
  }
}

// Watchers

export function* watchUploadRecognition() {
  yield takeLatest(types.UPLOAD_RECOGNITION, uploadRecognition);
}

export function* watchUploadExam() {
  yield takeLatest(types.UPLOAD_EXAM, uploadExam);
}

export function* watchUploadExamMobile() {
  yield takeLatest(types.UPLOAD_EXAM_MOBILE, uploadExamMobile);
}

export function* watchFetchClassifications() {
  yield takeLatest(types.FETCH_CLASSIFICATIONS, fetchClassifications);
}

export function* watchFetchRecognized() {
  yield takeLatest(types.FETCH_RECOGNIZED, fetchRecognized);
}

export function* watchFetchChangePage() {
  yield takeLatest(types.FETCH_CHANGE_PAGE, fetchChangePage);
}

export function* watchClassifyExam() {
  yield takeLatest(types.CLASSIFY_EXAM, classifyExam);
}

export function* watchSendImages() {
  yield takeLatest(types.SEND_IMAGES, sendImages);
}

export function* watchFetchExam() {
  yield takeLatest(types.FETCH_EXAM, fetchExam);
}

export function* watchFetchExamResult() {
  yield takeLatest(types.FETCH_EXAM_RESULT, fetchExamResult);
}

export function* watchFetchOnlineExam() {
  yield takeLatest(types.FETCH_ONLINE_EXAM, fetchOnlineExam);
}

export function* watchFetchOnlineExamInfo() {
  yield takeLatest(types.FETCH_ONLINE_EXAM_INFO, fetchOnlineExamInfo);
}

export function* watchFetchResultExam() {
  yield takeLatest(types.FETCH_RESULT_EXAM, fetchResultExam);
}

export function* watchFetchIndividualResultExam() {
  yield takeLatest(
    types.FETCH_INDIVIDUAL_RESULT_EXAM,
    fetchIndividualResultExam
  );
}

export function* watchFetchStudentExams() {
  yield takeLatest(types.FETCH_STUDENT_EXAMS, fetchStudentExams);
}

export function* watchFetchStudentResult() {
  yield takeLatest(types.FETCH_STUDENT_RESULT, fetchStudentResult);
}

export function* watchFetchStudentResultAll() {
  yield takeLatest(types.FETCH_STUDENT_RESULT_ALL, fetchStudentResultAll);
}

export function* watchFetchStudentResultExam() {
  yield takeLatest(types.FETCH_STUDENT_RESULT_EXAM, fetchStudentResultExam);
}

export function* watchFetchStudentIndividualResultExam() {
  yield takeLatest(
    types.FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM,
    fetchStudentIndividualResultExam
  );
}

export function* watchAnsQuestion() {
  yield takeLatest(types.ANS_QUESTION, ansQuestion);
}

export function* watchStartOnlineExam() {
  yield takeLatest(types.START_ONLINE_EXAM, startOnlineExam);
}

export function* watchEndOnlineExam() {
  yield takeLatest(types.END_ONLINE_EXAM, endOnlineExam);
}

export function* watchDownloadExam() {
  yield takeLatest(types.DOWNLOAD_EXAM, downloadExam);
}

export function* watchFinishExam() {
  yield takeLatest(types.FINISH_EXAM, finishExam);
}

export function* watchFetchExams() {
  yield takeLatest(types.FETCH_EXAMS, fetchExams);
}

export function* watchFetchExamMatrixEF() {
  yield takeLatest(types.FETCH_EXAM_MATRIX_EF, fetchExamMatrixEF);
}

export function* watchFetchExamMatrixEM() {
  yield takeLatest(types.FETCH_EXAM_MATRIX_EM, fetchExamMatrixEM);
}

export function* watchFetchExamHabilities() {
  yield takeLatest(types.FETCH_EXAM_HABILITIES, fetchExamHabilities);
}


export function* watchDeleteExam() {
  yield takeLatest(types.DELETE_EXAM, deleteExam);
}

export function* watchDeleteTag() {
  yield takeLatest(types.DELETE_TAG, deleteTagExam);
}

export function* watchAddTagExam() {
  yield takeLatest(types.ADD_TAG, addTagExam);
}

export function* watchFetchSelected() {
  yield takeLatest(types.FETCH_SELECTED, fetchSelected);
}

export function* watchDeleteQuestion() {
  yield takeLatest(types.DELETE_QUESTION, deleteQuestion);
}

export function* watchSearchExamTag() {
  yield takeLatest(types.SEARCH_EXAM_TAG, searchExamTag);
}

export function* watchCreateExam() {
  yield takeLatest(types.CREATE_EXAM, createExam);
}

export function* watchUpdateExam() {
  yield takeLatest(types.UPDATE_EXAM, uploadExam);
}

export function* watchFetchExamImage() {
  yield takeLatest(types.UPDATE_EXAM, fetchExamImage);
}

export function* watchFetchExamReport() {
  yield takeLatest(types.FETCH_EXAM_REPORT, fetchExamReport);
}

export function* watchFetchEquationImage() {
  yield takeLatest(types.UPDATE_EXAM, fetchEquationImage);
}

export function* watchSearchSelected() {
  yield takeLatest(types.SEARCH_SELECTED, searchSelected);
}

export function* watchCheckPagesAvailable() {
  yield takeLatest(types.CHECK_PAGES_AVAILABLE, checkPagesAvailable);
}

export function* watchOpenTestUpdate() {
  yield takeLatest(types.OPEN_TEST_UPDATE, openTestUpdate);
}

export function* watchFetchPowerBIReport() {
  yield takeLatest(types.FETCH_POWERBI_REPORT, fetchPowerBIReport);
}

export function* watchCreateQuestionsByAI() {
  yield takeLatest(types.CREATE_QUESTIONS_BY_AI, createQuestionsByAI);
}

export function* watchGetLoadedVideos() {
  yield takeLatest(types.GET_VIDEOS_LOADED, getVideosLoaded);
}

export function* watchFetchAnoTrilhas() {
  yield takeLatest(types.FETCH_ANO_TRILHAS, fetchAnoTrilhas);
}

export function* watchFetchDisciplinasTrilhas() {
  yield takeLatest(types.FETCH_DISCIPLINAS_TRILHAS, fetchDisciplinasTrilhas);
}

// export function* watchFetchStudentsTrilhas() {
//   yield takeLatest(types.FETCH_STUDENTS_TRILHAS, fetchStudentsTrilhas);
// }

export function* watchFetchResultTrilhas() {
  yield takeLatest(types.FETCH_RESULT_TRILHAS, fetchResultTrilhas);
}

export function* watchFetchUnidadesTematicas() {
  yield takeLatest(types.FETCH_UNIDADES_TEMATICAS, fetchUnidadesTematicas);
}

export function* watchFetchReadAndWriting() {
  yield takeLatest(types.FETCH_READ_AND_WRITING, fetchReadAndWriting);
}

export function* watchFetchReadindAndWritingCSVData() {
  yield takeLatest(types.FETCH_READING_AND_WRITING_DATA_CSV, fetchReadAndWritingCSVData);
}

export function* watchUpdateResultRW() {
  yield takeLatest(types.UPDATE_RESULT_RW, updateResultRW);
}

export function* watchFetchConsolidacao() {
  yield takeLatest(types.FETCH_CONSOLIDACAO, fetchConsolidacao);
}

export default function* () {
  yield all([
    watchCheckPagesAvailable(),
    watchUploadRecognition(),
    watchUploadExam(),
    watchUploadExamMobile(),
    watchClassifyExam(),
    watchSendImages(),
    watchFetchClassifications(),
    watchFetchRecognized(),
    watchFetchChangePage(),
    watchFetchExam(),
    watchFetchExamReport(),
    watchFetchExamResult(),
    watchFetchOnlineExam(),
    watchFetchOnlineExamInfo(),
    watchFetchResultExam(),
    watchFetchIndividualResultExam(),
    watchFetchStudentExams(),
    watchFetchStudentResult(),
    watchFetchStudentResultExam(),
    watchFetchStudentIndividualResultExam(),
    watchAnsQuestion(),
    watchStartOnlineExam(),
    watchEndOnlineExam(),
    watchFetchExamImage(),
    watchFetchEquationImage(),
    watchDownloadExam(),
    watchFinishExam(),
    watchFetchExams(),
    watchFetchExamMatrixEF(),
    watchFetchExamMatrixEM(),
    watchFetchExamHabilities(),
    watchDeleteExam(),
    watchDeleteTag(),
    watchAddTagExam(),
    watchFetchSelected(),
    watchDeleteQuestion(),
    watchSearchExamTag(),
    watchUpdateExam(),
    watchCreateExam(),
    watchSearchSelected(),
    watchOpenTestUpdate(),
    watchFetchPowerBIReport(),
    watchCreateQuestionsByAI(),
    watchGetLoadedVideos(),
    watchFetchAnoTrilhas(),
    watchFetchDisciplinasTrilhas(),
    watchFetchResultTrilhas(),
    watchFetchUnidadesTematicas(),
    watchFetchReadAndWriting(),
    watchFetchReadindAndWritingCSVData(),
    watchUpdateResultRW(),
    watchFetchConsolidacao(),
    watchFetchStudentResultAll()
  ]);
}
