import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateResultRW } from "../../../store/exams/actions";
import { Grid, MenuItem, Select, TableCell, Tooltip } from "@material-ui/core";
import { message } from "antd";

const ProsodySelect = ({
  studentsResult,
  key,
  style,
  studentId,
  month,
  year,
  updateResultRW,
  setResultRW,
}) => {
  const handleProsody = (event, studentId) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    const option = event.target.value;

    studentData["Reading"]["Prosodia"] = option;

    const resultRW = setResultRW(month, studentId, studentData);
    updateResultRW(resultRW, studentData, year);
    message.info("Salvando marcação...");
  };

  const getFieldValue = () => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];

    return studentData["Reading"]["Prosodia"]
      ? studentData["Reading"]["Prosodia"]
      : "";
  };

  return (
    <TableCell
      key={key}
      component="th"
      scope="row"
      className={style}
    >
      <Grid container direction="row" justifyContent="center">
        <Tooltip title={"Leu com ritmo e entonação?"}>
          <Select
            value={getFieldValue()}
            onChange={(event) => handleProsody(event, studentId)}
          >
            <MenuItem value="Não">Não</MenuItem>
            <MenuItem value="Em Parte">Em Parte</MenuItem>
            <MenuItem value="Sim">Sim</MenuItem>
          </Select>
        </Tooltip>
      </Grid>
    </TableCell>
  );
};

ProsodySelect.propTypes = {
  updateResultRW: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams }) => ({
  updateRW: exams.updateRW,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateResultRW,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProsodySelect);
