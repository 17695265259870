import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Tooltip, Typography } from "@material-ui/core";

const BorderLinearProgress = withStyles((theme) => {
  return {
    root: {
      width: 24,
    },
    barColorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      transform: ({ value }) => {
        return `translateY(${value}%) !important`;
      },
      backgroundColor: ({ color }) => color,
    },
  };
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: theme.palette.grey[200],
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: ({ color }) => color,
    },
  },
}));

export default function CustomizedProgressBars({
  consolidacaoValue,
  percent,
  rawNumber = 0,
  hasProgressBar = true,
  label = false,
  vertical = false,
  withoutTooltip = false,
  tooltip = undefined,
  color = "primary",
  labelBottom = "",
  labelTop = null,
  progressHeight = 256,
  showRawNumber = false,
  position = "start"
}) {
  const classes = useStyles({ color });
  if (isNaN(percent)) {
    percent = 0;
  }

  let percentString;
  if (percent >= 1) {
    percent = 1;
    percentString = showRawNumber ? rawNumber : "100%";
  } else {
    if (showRawNumber) {
      percentString = Number.isInteger(rawNumber) ? rawNumber : rawNumber.toFixed(2);
    } else {
      percentString = (percent * 100).toFixed(1).toString() + "%";
    }
  }

  let progressBar;
  let linearProgress;

  if (vertical) {
    linearProgress = (
      <BorderLinearProgress
        key={percent}
        style={{
          height: progressHeight,
        }}
        variant="determinate"
        color={color}
        value={100 - percent * 100}
      />
    );
  } else {
    linearProgress = (
      <LinearProgress
        style={{ height: "12px" }}
        variant="determinate"
        value={percent * 100}
        className={classes.root}
      />
    );
  }

  if (label) {
    progressBar = (
      <Box display={vertical ? "" : "flex"} alignItems="center">
        {hasProgressBar ? (
          <Box width="100%" mr={1} justifyContent={position} display={position === "center" ? "flex" : ""}>
            {linearProgress}
          </Box>
        ) : null}
        <Box minWidth={42} style={{ textAlign: position }}>
          <Typography
            style={{ marginBottom: "0px" }}
            variant="body2"
            color="textSecondary"
          >
            {percentString}
          </Typography>
        </Box>
        {labelBottom ? (
          <Box style={{ textAlign: position }}>
            <Typography
              style={{ marginBottom: "0px" }}
              variant="body2"
              color="textSecondary"
            >
              {labelBottom}
            </Typography>
          </Box>
        ) : null}
        {labelTop ? (
          <Box style={{ textAlign: position }}>
            <Typography
              style={{ marginBottom: "0px" }}
              variant="body2"
              color="textSecondary"
            >
              T:{labelTop}
            </Typography>
          </Box>
        ) : null}
      </Box>
    );
  } else {
    progressBar = linearProgress;
  }

  if (withoutTooltip) {
    return progressBar;
  }

  let title = consolidacaoValue ? consolidacaoValue : percentString;
  if (tooltip) title = tooltip + ": " + title;

  return <Tooltip title={title}>{progressBar}</Tooltip>;
}
