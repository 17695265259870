import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateResultRW } from "../../../store/exams/actions";
import { Grid, MenuItem, Select, TableCell, Tooltip } from "@material-ui/core";
import { message } from "antd";

const ComprehensionSelect = ({
  studentsResult,
  key,
  style,
  studentId,
  month,
  year,
  updateResultRW,
  setResultRW,
}) => {
  const handleComprehension = (event, studentId) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    const option = event.target.value;
    studentData["Reading"]["CompreensaoDeLeitura"] = Number(option);
    const resultRW = setResultRW(month, studentId, studentData);
    updateResultRW(resultRW, studentData, year);
    message.info("Salvando marcação...");
  };

  const getFieldValue = (studentId) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];

    return String(studentData["Reading"]["CompreensaoDeLeitura"]);
  };

  return (
    <TableCell key={key} component="th" scope="row" className={style}>
      <Grid container direction="row" justifyContent="center">
        <Tooltip title={"Total de acertos"}>
          <Select
            value={getFieldValue(studentId)}
            onChange={(event) => handleComprehension(event, studentId)}
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
          </Select>
        </Tooltip>
      </Grid>
    </TableCell>
  );
};

ComprehensionSelect.propTypes = {
  updateResultRW: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams }) => ({
  updateRW: exams.updateRW,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateResultRW,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComprehensionSelect);
